import { Avatar, Box, IconButton, Link, ListItem, ListItemAvatar, ListItemButton, ListItemText, Menu, Tooltip, Typography } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import config from "../../config";
import { Close, Email, LinkedIn, MoreVert } from "@mui/icons-material";
import { grey } from "@mui/material/colors";

export default function ListFavoritesContainer( props) {

    const {item, deleteItem} = props;
    const [anchorEl, setAnchorEl] = useState(null);

    const navigate = useNavigate(); 

    const open = Boolean(anchorEl);

    const {firstname, lastname, avatarUrl, email, linkedInUrl} = item.user;

    const {profileMacroType} = item


    const onClickAction = (item) => {
        navigate('/user/' + item.user.id + '/profile/' + item.id)
    }

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    }



    return (

        <ListItem sx={{ position: 'relative', display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%', p: 1, mb: 1, 
            borderRadius: 1, 
            border: `1px solid ${grey[300]}`, 
            bgcolor: 'background.paper', 
            boxShadow: 1
            }}>
            
            {deleteItem && 
                <IconButton onClick={deleteItem} sx={{position: 'absolute', top: -5, right: -5}} aria-label="x" size="small">
                    {/* <Typography variant="body2">x</Typography> */}
                    <Close sx={{fontSize : 14}} color="secondary" />
                </IconButton>
            }
            
            <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center'}} >
                <ListItemAvatar>
                    <Avatar 
                        sx={{ cursor: 'pointer',  mr: 1}}
                        alt={firstname + ' ' + lastname} 
                        onClick={() => onClickAction(item)} aria-label={firstname + ' ' + lastname} title={firstname + ' ' + lastname}
                        src={avatarUrl ? config.server.backetUrl + avatarUrl : null}>{firstname.toUpperCase().charAt(0)}</Avatar>
                </ListItemAvatar>

                <ListItemText
                    sx={{ flexGrow: 1, m: 0, p: 0 }}
                    
                    primary={<Typography 
                        onClick={() => onClickAction(item)} aria-label={firstname + ' ' + lastname} title={firstname + ' ' + lastname}
                        sx={{ cursor: 'pointer', fontSize: '.8rem', fontWeight: 'bold' }} 
                        variant="body">{firstname + ' ' + lastname}</Typography>}

                    secondary={<Typography 
                        onClick={() => onClickAction(item)} aria-label={firstname + ' ' + lastname} title={firstname + ' ' + lastname}
                        sx={{ cursor: 'pointer', fontSize: '.8rem' }} 
                        variant="body" 
                        display="block" gutterBottom color="text.primary">{profileMacroType.name}</Typography>}
                />
            </Box>

            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>

                <Tooltip title="Contacts">
                    <IconButton
                        onClick={handleClick}
                        size="small"
                    >
                        <Email color="primary" />
                    </IconButton>
                </Tooltip>

                <Menu
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    onClick={handleClose}

                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                >

                    {email && 
                    
                        <ListItemButton component={Link} target="_blank" href={`mailto:${email}`} >
                            <Email color="primary" sx={{ mr: 1, fontSize: 20 }} />
                            <ListItemText ><Typography variant="body2">{email}</Typography></ListItemText>
                        </ListItemButton>    
                    }

                    {linkedInUrl &&
                        <ListItemButton component={Link} target="_blank" href={linkedInUrl} >
                            <LinkedIn color="primary" sx={{ mr: 1, fontSize: 20 }} />
                            <ListItemText ><Typography variant="body2">LinkedIn</Typography></ListItemText>
                        </ListItemButton>
                    }

                </Menu>

            </Box>
        </ListItem>
    )
}
