
import {Avatar, Box,  Dialog, DialogContent,  Divider, Icon, IconButton, List, ListItem, ListItemAvatar, ListItemButton, ListItemText, Typography, useMediaQuery} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Fragment, useEffect, useState } from 'react';
import TooltipContentProfile from './TooltipContentProfile';
import BorderLinearProgress from './BorderLinearProgress';
import { Close, Place } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import config from '../../config';



// function TooltipContent(props) {

//     const { children } = props;

//     const navigate = useNavigate();

//     // const onClickMail = (email) => {
//     //     window.location.href = 'mailto:' + email
//     // };

//     // const onClickLinkedIn = (url) => {
//     //     window.open(url, '_blank');
//     // }

//     const { profile, event, type } = children;


//     return (

//         <Box sx={{
//             p: 1,
//             mb: 1,
//             w: '100%',
//             border: 3,
//             borderImage: 'linear-gradient(to right, rgba(192, 8, 127, 0.7) 0%, rgba(192, 8, 127, 0.7) 22%,rgba(236, 227, 20, 0.7) 100%)',
//             borderImageSlice: 1,
//             borderRadius: '5px',
//             backgroundColor: '#eee',
//             color: '#000'
//         }} >

//             {type === undefined && (
//                 <>
//                     {profile && (
//                         <>

//                             <TooltipContentProfile profile={profile} />
//                             {/* <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', }}>

//                                 <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
//                                     <ListItemAvatar>
//                                         <Avatar alt={profile.user.firstname + ' ' + profile.user.lastname}
//                                             src={children.avatarUrl ? config.server.backetUrl + children.avatarUrl : null}>{profile.user.firstname.charAt(0)}</Avatar>
//                                     </ListItemAvatar>

//                                     <ListItemText
//                                         sx={{ flexGrow: 1 }}
//                                         primary={<Typography sx={{ fontSize: '.9rem', fontWeight: 'bold' }} variant="h6">{profile.user.firstname + ' ' + profile.user.lastname}</Typography>}
//                                         secondary={<Typography sx={{ fontSize: '.8rem' }} variant="button" display="block" gutterBottom color="text.primary">{children.profileMacroType}</Typography>}
//                                     />
//                                 </Box>
//                             </Box>


//                             <Box sx={{ mb: 2 }}>
//                                 <Typography variant="caption" color="text.secondary">{children.shortDescription ?? 'shortDescription'}</Typography>
//                             </Box>

//                             <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', mb: 2 }}>
//                                 <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
//                                     <Place color="primary" sx={{ mr: 1 }} /> <Typography variant="body2" color="text.secondary">{children.place}</Typography>
//                                 </Box>
//                             </Box>

//                             <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
//                                 <Typography sx={{ textAlign: 'center', fontWeight: 'bold' }} variant="button" color="text.secondary">match score</Typography>
//                             </Box>

//                             <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', mb: 2 }}>
//                                 <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative' }}>
//                                     <Typography sx={{ position: 'absolute', zIndex: '9999', fontWeight: 'bold' }} variant="body2" color="white">{profile.match}%</Typography>
//                                     <BorderLinearProgress sx={{ width: '100%' }} variant="determinate" value={profile.match} />
//                                 </Box>
//                             </Box>


//                             <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
//                                 {profile.user.email && <IconButton size="small" onClick={e => { onClickMail(profile.user.email) }}><Tooltip title={profile.user.email}><Email fontSize="inherit" color="primary" /></Tooltip></IconButton>}
//                                 {profile.user.linkedInUrl && <IconButton size="small" onClick={e => { onClickLinkedIn(profile.user.linkedInUrl) }}><LinkedIn fontSize="inherit" color="primary" /></IconButton>}

//                                 <IconButton size="small" onClick={e => { navigate('/user/' + profile.user.id + '/profile/' + profile.id) }}><ArrowRightAlt fontSize="inherit" color="primary" /></IconButton>
//                             </Box> */}

//                         </>
//                     )}

//                     {event && (
//                         <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', pb: 0 }}>

//                             <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
//                                 <ListItemAvatar>
//                                     <Avatar alt={event.user.firstname + ' ' + event.user.lastname} src={event.user.avatarUrl ? config.server.backetUrl + event.user.avatarUrl : null}>{event.user.firstname.charAt(0)}</Avatar>
//                                 </ListItemAvatar>

//                                 <ListItemText
//                                     sx={{ flexGrow: 1 }}
//                                     primary={<Typography sx={{ fontSize: '.9rem', fontWeight: 'bold' }} variant="h6">{event.user.firstname + ' ' + event.user.lastname}</Typography>}
//                                 />
//                             </Box>

//                             <hr style={{ width: '100%', border: '1px solid #ddd' }} />

//                             <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
//                                 <Typography sx={{ mb: 1 }} variant="body1" color="text.secondary">{event.name ?? 'Title'}</Typography>
//                                 <Typography sx={{ mb: 2 }} variant="body2" color="text.secondary">{event.description ?? 'Description'}</Typography>
//                             </Box>


//                             <Box sx={{ width: '100%', mb: 1 }}>

//                                 <Stack spacing={1}>

//                                     {event.date_at && (
//                                         <Stack spacing={1} direction="row" alignItems="center">
//                                             <CalendarIcon color="primary" />
//                                             <Typography variant="body2" noWrap>{new Date(event.date_at).toLocaleDateString()} {new Date(event.date_at).toLocaleTimeString().slice(0, -3)}</Typography>
//                                         </Stack>
//                                     )}

//                                     {event.place && (
//                                         <Stack title={event.place} spacing={1} direction="row" alignItems="center">
//                                             <Room color="primary" />
//                                             <Typography variant="body2" noWrap>{event.place}</Typography>
//                                         </Stack>
//                                     )}

//                                     {event.fee && (
//                                         <Stack title={event.fee} spacing={1} direction="row" alignItems="center">
//                                             <Euro color="primary" />
//                                             <Typography variant="body2" noWrap>{event.fee}</Typography>
//                                         </Stack>
//                                     )}

//                                     {event.url && (
//                                         <Stack title={event.url} spacing={1} direction="row" alignItems="center">
//                                             <Language color="primary" />
//                                             <Link href={event.url} target="_blank" rel="noreferrer" variant="body2" noWrap>Web page</Link>
//                                         </Stack>
//                                     )}

//                                 </Stack>
//                             </Box>


//                             <Typography sx={{ textAlign: 'center', mb: 1, fontWeight: 'bold' }} variant="button" color="text.secondary">Match score!</Typography>

//                             <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', mb: 2 }}>
//                                 <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative' }}>
//                                     <Typography sx={{ position: 'absolute', zIndex: '9999', fontWeight: 'bold' }} variant="body2" color="white">{event.match}%</Typography>
//                                     <BorderLinearProgress sx={{ width: '100%' }} variant="determinate" value={event.match} />
//                                 </Box>
//                             </Box>
//                         </Box>
//                     )}

//                 </>

//             )}

//             {children.elements !== undefined && (

//                 <List
//                     subheader={
//                         <>
//                             <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
//                                 <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
//                                     <Place color="primary" sx={{ mr: 1 }} /> <Typography variant="body2" color="text.secondary">{children.place}</Typography>
//                                 </Box>
//                             </Box>
//                             <hr style={{ width: '100%', border: '1px solid #ddd' }} />
//                         </>
//                     }

//                     sx={{
//                         width: '100%',
//                         position: 'relative',
//                         overflow: 'auto',
//                         maxHeight: 320,
//                         '& ul': { padding: 0, margin: 0 },

//                     }} >

//                     {children.elements.map((element, index) => (

//                         <Fragment key={index}>
                           
//                             {element.profile && (
//                                 <ListItem key={index} sx={{ p: 0, mb: 2, border: '1px solid #ccc' }} >

//                                     <ListItemButton sx={{ display: 'flex', flexDirection: 'column' }} onClick={e => navigate('/user/' + element.profile.user.id + '/profile/' + element.profile.id)} >

//                                         <Box sx={{ display: 'flex', width: '100%', mb: 2 }} >
//                                             <ListItemAvatar>
//                                                 <Avatar alt={element.profile.user.firstname + ' ' + element.profile.user.lastname}
//                                                     src={element.avatarUrl ? config.server.backetUrl + element.avatarUrl : null}>{element.profile.user.firstname.charAt(0)}</Avatar>
//                                             </ListItemAvatar>

//                                             <ListItemText
//                                                 sx={{ flexGrow: 1, p: 0, m: 0 }}
//                                                 primary={<Typography sx={{ fontSize: '.9rem', fontWeight: 'bold' }} variant="h6">{element.profile.user.firstname + ' ' + element.profile.user.lastname}</Typography>}
//                                                 secondary={<Typography sx={{ fontSize: '.8rem' }} variant="button" gutterBottom color="text.primary">{element.profileMacroType}</Typography>}
//                                             />
//                                         </Box>


//                                         <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
//                                             <Typography sx={{ textAlign: 'center', fontWeight: 'bold' }} variant="button" color="text.secondary">match score</Typography>
//                                         </Box>

//                                         <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
//                                             <BorderLinearProgress length={10} sx={{ width: '100%', mr: 2 }} variant="determinate" value={element.profile.match} />
//                                             <Typography sx={{ fontWeight: 'bold' }} variant="body2" color="text.primary">{element.profile.match}%</Typography>
//                                         </Box>

//                                     </ListItemButton>


//                                 </ListItem>
//                             )}


//                             {element.event && (

//                                 <ListItem key={index} sx={{ p: 0, mb: 2, border: '1px solid #ccc' }} >

//                                     {console.log(element.event)}

//                                     <ListItemButton sx={{ display: 'flex', flexDirection: 'column' }} component="a" target="blank" href={element.event.url ?? undefined} >
                                            
//                                         <Box sx={{ display: 'flex', width: '100%', mb: 2 }} >
//                                             <ListItemAvatar>
//                                                 <Avatar alt={element.event.user.firstname + ' ' + element.event.user.lastname}
//                                                     src={element.event.user.avatarUrl ? config.server.backetUrl + element.event.user.avatarUrl : null}>{element.event.user.firstname.charAt(0)}</Avatar>
//                                             </ListItemAvatar>

//                                             <ListItemText
//                                                 sx={{ flexGrow: 1, p: 0, m: 0 }}
//                                                 primary={<Typography sx={{ fontSize: '.9rem', fontWeight: 'bold' }} variant="h6">{element.event.name}</Typography>}
//                                                 secondary={<Typography variant="caption" display="block" gutterBottom color="text.primary">{new Date(element.event.date_at).toLocaleDateString()} {new Date(element.event.date_at).toLocaleTimeString().slice(0, -3)}</Typography>}
//                                             />
//                                         </Box>


//                                         <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
//                                             <Typography sx={{ textAlign: 'center', fontWeight: 'bold' }} variant="button" color="text.secondary">match score</Typography>
//                                         </Box>

//                                         <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
//                                             <BorderLinearProgress length={10} sx={{ width: '100%', mr: 2 }} variant="determinate" value={element.event.match} />
//                                             <Typography sx={{ fontWeight: 'bold' }} variant="body2" color="text.primary">{element.event.match}%</Typography>
//                                         </Box>

//                                     </ListItemButton>

//                                 </ListItem>
//                             )}

//                         </Fragment>


//                     ))}

//                 </List>
//             )}

//         </Box>
//     );
// }


export default function AlertDialog(props) {

    const navigate = useNavigate();

    const theme = useTheme();
    const fullWidth = useMediaQuery(theme.breakpoints.down('sm'));

    const { open , handleClose} = props;

    const [data, setData] = useState({
        profile: null,
        marker: null,
        elements: null
    });

    useEffect(() => { 

        if (open){
            if (open.type) {
                setData({marker: open, profile :null, elements: open.elements});
            }
            else {
                setData({marker: open, profile : open.profile, elements: null});
            }
        }

    }, [open]);


    const onClickAction = (profile) => {
        handleClose();
        navigate('/user/' + profile.user.id + '/profile/' + profile.id);
    }

    return (
        <Dialog open={open} onClose={handleClose} fullWidth={fullWidth}  >


            <DialogContent sx={{p: 0}}>

                <Box sx={{
                        p: 1,
                        width: {xs : 'auto', sm : 320} ,
                        border: 3,
                        borderImage: 'linear-gradient(to right, rgba(192, 8, 127, 0.7) 0%, rgba(192, 8, 127, 0.7) 22%,rgba(236, 227, 20, 0.7) 100%)',
                        borderImageSlice: 1,
                        borderRadius: '5px',
                        backgroundColor: 'background.paper',
                        position: 'relative',
                    }} >

                    <Box sx={{ position: 'absolute', top: 0, right: 0 }}>
                        <IconButton size="small" onClick={handleClose}><Close fontSize="inherit" color="primary" /></IconButton>
                    </Box>



                    {data.profile && <TooltipContentProfile profile={data.profile} onClick={handleClose} />}

                    {data.elements && (

                        <>
                            
                            <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', mb: 1 }}>
                                <Place color="primary" sx={{ mr: 1 }} /> 
                                <Typography variant="body2" color="text.secondary">{data.marker.place}</Typography>
                            </Box>
                            

                            <Divider sx={{mb: 1}} />
                            
                            <List sx={{
                                    width: '100%',
                                    '& ul': { padding: 0, margin: 0 },
                                    overflow: 'auto',
                                    maxHeight: 320,
                                }} >

                                {data.elements.map((element, index) => (

                                    <Fragment key={index}>

                                        {element.profile && (
                                            <ListItem key={index} sx={{ p: 0, mb: 2, border: '1px solid #ccc' }} >

                                                <ListItemButton sx={{ display: 'flex', flexDirection: 'column' }} onClick={() => onClickAction(element.profile)} >

                                                    <Box sx={{ display: 'flex', width: '100%', mb: 2 }} >
                                                        <ListItemAvatar>
                                                            <Avatar alt={element.profile.user.firstname + ' ' + element.profile.user.lastname}
                                                                src={element.avatarUrl ? config.server.backetUrl + element.avatarUrl : null}>{element.profile.user.firstname.charAt(0)}</Avatar>
                                                        </ListItemAvatar>

                                                        <ListItemText
                                                            sx={{ flexGrow: 1, p: 0, m: 0 }}
                                                            primary={<Typography sx={{ fontSize: '.9rem', fontWeight: 'bold' }} variant="h6">{element.profile.user.firstname + ' ' + element.profile.user.lastname}</Typography>}
                                                            secondary={<Typography sx={{ fontSize: '.8rem' }} variant="button" gutterBottom color="text.primary">{element.profileMacroType}</Typography>}
                                                        />
                                                    </Box>


                                                    <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                        <Typography sx={{ textAlign: 'center', fontWeight: 'bold' }} variant="button" color="text.secondary">match score</Typography>
                                                    </Box>

                                                    <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                        <BorderLinearProgress length={10} sx={{ width: '100%', mr: 2 }} variant="determinate" value={element.profile.match} />
                                                        <Typography sx={{ fontWeight: 'bold' }} variant="body2" color="text.primary">{element.profile.match}%</Typography>
                                                    </Box>

                                                </ListItemButton>


                                            </ListItem>
                                        )}

{/* 
                                        {element.event && (

                                            <ListItem key={index} sx={{ p: 0, mb: 2, border: '1px solid #ccc' }} >

                                                {console.log(element.event)}

                                                <ListItemButton sx={{ display: 'flex', flexDirection: 'column' }} component="a" target="blank" href={element.event.url ?? undefined} >

                                                    <Box sx={{ display: 'flex', width: '100%', mb: 2 }} >
                                                        <ListItemAvatar>
                                                            <Avatar alt={element.event.user.firstname + ' ' + element.event.user.lastname}
                                                                src={element.event.user.avatarUrl ? config.server.backetUrl + element.event.user.avatarUrl : null}>{element.event.user.firstname.charAt(0)}</Avatar>
                                                        </ListItemAvatar>

                                                        <ListItemText
                                                            sx={{ flexGrow: 1, p: 0, m: 0 }}
                                                            primary={<Typography sx={{ fontSize: '.9rem', fontWeight: 'bold' }} variant="h6">{element.event.name}</Typography>}
                                                            secondary={<Typography variant="caption" display="block" gutterBottom color="text.primary">{new Date(element.event.date_at).toLocaleDateString()} {new Date(element.event.date_at).toLocaleTimeString().slice(0, -3)}</Typography>}
                                                        />
                                                    </Box>


                                                    <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                        <Typography sx={{ textAlign: 'center', fontWeight: 'bold' }} variant="button" color="text.secondary">match score</Typography>
                                                    </Box>

                                                    <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                        <BorderLinearProgress length={10} sx={{ width: '100%', mr: 2 }} variant="determinate" value={element.event.match} />
                                                        <Typography sx={{ fontWeight: 'bold' }} variant="body2" color="text.primary">{element.event.match}%</Typography>
                                                    </Box>

                                                </ListItemButton>

                                            </ListItem>
                                        )} */}

                                    </Fragment>


                                ))}

                            </List>

                        </>
                    )}
                </Box>

            </DialogContent>
            
        </Dialog>
    );
}