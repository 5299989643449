import config from "../config";
import React, { useEffect, useState } from 'react'
import { Avatar, Box, Button, CircularProgress, Container, CssBaseline, Snackbar, TextField, Typography } from "@mui/material";
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { useNavigate, useSearchParams } from "react-router-dom";
import useBrowserLanguageDetector from '../hooks/useBrowserLanguageDetector'

import { strings } from '../strings.js'
import axios from "axios";
import ServerProxy from "./tools/serverProxy.js";

const url = config.server.protocol + '://' + config.server.host + ':' + config.server.port + config.server.prefix;


export default function Login(props) {

    const [account, setAccount] = useState({
        grant_type: 'password',
        username: '',
        password: '',
    });

    const [render, setRender] = useState(false);
    const [searchParams] = useSearchParams();

    const queryToken = searchParams.get('t');
    

    const navigate = useNavigate();

    useEffect(() => {
    
        async function login(t) {

            //console.log('login', t);
            const token = await ServerProxy.loginToken(t);

            if (token){
                return window.location.assign('/');
            }
            else{
                return window.location.assign(config.accelerActionUrl);
            }
        }


        if (queryToken){
            login(queryToken);
        }
        else {
            setRender(true);
        }

    }, [queryToken]);


    const handleUserChange = (newUsername) => {
        setAccount({ ...account, username: newUsername, });
    };


    const handlePasswordChange = (newPassword) => {
        setAccount({ ...account, password: newPassword, });
    };


    const handleClickLoginEvent = async () => {

        const { username, password, grant_type } = account
        
        try {

            const { data } = await axios.post(url + '/oauth/access_token',
                { username, password, grant_type }
            )

            if (data.token) {

                sessionStorage.setItem('role', 'admin');

                data.ctime = new Date().getTime();
                sessionStorage.setItem('token', JSON.stringify(data));

                navigate(0);
            } else {

                sessionStorage.clear();
            }

        } catch (error) {

            sessionStorage.clear();

            if (error.response.status === 401) {
                setErrorMessage(error.response.data.message);
            }
            
            // setExeLogin(1);
            // setTimeout(() => {
            //     setExeLogin(0);
            // }, 3000);

           
        }
    }

  
    const [errorMessage, setErrorMessage] = useState(null);


    const vertical = 'bottom';
    const horizontal = 'center';

    return (
        <>
            {render ?

                (<Container component="main" maxWidth="xs">
                    <CssBaseline />

                    <Snackbar
                        anchorOrigin={{ vertical, horizontal }}
                        open={ errorMessage ? new Boolean(errorMessage) : false}
                        onClose={ () => setErrorMessage(null) }
                        message={errorMessage}
                        key={vertical + horizontal}
                        />

                    <Box
                        sx={{
                            marginTop: 8,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <Avatar sx={{ width: 100, height: 100, mb: 4, bgcolor: 'secondary.main' }}>
                            <LockOutlinedIcon sx={{ fontSize: 80 }} />
                        </Avatar>
                        <Typography component="h1" variant="h5">
                            {strings.logIn}
                        </Typography>

                        <Box noValidate sx={{ mt: 5 }}>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="username"
                                label={strings.userName}
                                name="username"
                                autoComplete="username"
                                value={account.username}
                                onChange={(e) => handleUserChange(e.target.value)}
                                InputLabelProps={{ shrink: true }}
                                autoFocus
                            />

                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label={strings.password}
                                type="password"
                                value={account.password}
                                InputLabelProps={{ shrink: true }}
                                onChange={(e) => handlePasswordChange(e.target.value)}

                                id="password"
                                autoComplete="current-password"
                            />

                            {/* {exeLogin === 1 && (
                                <Typography sx={{ color: 'red' }}>
                                    Email or password is incorrect
                                </Typography>
                            )} */}



                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                onClick={handleClickLoginEvent}
                                sx={{ mt: 3 }}
                            >
                                {strings.logIn}
                            </Button>
                        </Box>
                    </Box>
                </Container>)
                :
                (<Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }} >
                    <CircularProgress />
                </Box>)
            }
        </>
    )
}