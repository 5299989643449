import { TripOrigin } from "@mui/icons-material";
import { Box, List, ListItem, Typography} from "@mui/material";
import { useTheme} from '@mui/material/styles';
import { grey } from "@mui/material/colors";

export default function BlockBox(props) {


    const theme = useTheme();
    const color = theme.palette.primary.main;

    const { title, data, sx={} , sizeTitle} = props;
    
    return (
        <Box sx={{ display: 'flex', ...sx }}>

            <Box sx={{ display: 'flex', flexDirection: "column", width: { xs: '100%', md: 'auto' }}}>

                <Typography sx={{mb: 1, fontWeight: 'bold', fontSize: sizeTitle ?  sizeTitle +'px' : '14px' ,  textAlign: 'start' }}>
                    {title}
                </Typography>

                <Box sx={{ width: 'auto',  backgroundColor: grey[100], borderRadius: '14px', p: 1 }}  >
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', p: 0, m: 0}}>
                        <List>
                            {data && data.map((value, index) => (
                                <ListItem sx={{m: 0, p: 1, py: .2 }} key={index}>
                                    <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                        <TripOrigin sx={{ color , fontSize: '12px',  mr : .8 }} />    
                                        <Typography variant="body2" sx={{ fontWeight: '400' }}>{value}</Typography>
                                    </Box>
                                </ListItem>
                            ))}
                        </List>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}
