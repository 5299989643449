
import { Box, Card, CardContent, Typography } from "@mui/material";
import { useEffect, useState } from "react";

import ExpertiseBox from "./ExpertiseBox";
import BlockBox from "./BlockBox";
import ServerProxy from "../tools/serverProxy";
import { MyDividerLarge, MyDividerSmall } from "./MyDivider";



export default function TrainingProvidersCard(props) {

    const { title = 'Training providers', idProfile, profileMacroType } = props;
    const [data, setData] = useState(null);

    useEffect(() => {

        const loadData = async () => {

            try {

                const additionalInformation = await ServerProxy.getAdditionalInformation(idProfile);
                if (additionalInformation) {


                    const expertiseOnMap = new Map();

                    const espertiseAreas = await ServerProxy.getEspertiseAreas();
                    for (const item of espertiseAreas) {
                        expertiseOnMap.set(item.id, item.name);
                    }


                    const industryMap = new Map();

                    const companyFocus = await ServerProxy.getCompanyFocus();
                    for (const item of companyFocus) {
                        industryMap.set(item.id, item.name);
                    }

                    const investorTypeMap = new Map();

                    const investorType = await ServerProxy.getInvestorType();
                    for (const item of investorType) {
                        investorTypeMap.set(item.id, item.name);
                    }


                    const topicMap = new Map();
                    const topic = await ServerProxy.getTopic();
                    for (const item of topic) {
                        topicMap.set(item.id, item.name);
                    }



                    const serviceDeliveryTypeMap = new Map();
                    const serviceDeliveryType = await ServerProxy.getServiceDeliveryType();
                    for (const item of serviceDeliveryType) {
                        serviceDeliveryTypeMap.set(item.id, item.name);
                    }


                    const categoriesTrainingMap = new Map();
                    const trainingProviderCategory = await ServerProxy.getTrainingProviderCategory();
                    for (const item of trainingProviderCategory) {
                        categoriesTrainingMap.set(item.id, item.name);
                    }


                    const typologyTrainingProvidersMap = new Map();
                    const typologyTrainingProviders = await ServerProxy.getTypology(profileMacroType === '2' ? 0 : 1);

                    for (const item of typologyTrainingProviders) {
                        typologyTrainingProvidersMap.set(item.id, item.name);
                    }

                    let expertiseOnSwap = additionalInformation.expertiseOn ? additionalInformation.expertiseOn.map((item) => expertiseOnMap.get(item.toString())) : null;
                    let knowAboutSwap = additionalInformation.knowAbout ? additionalInformation.knowAbout.map((item) => expertiseOnMap.get(item.toString())) : null;


                    let topicsSwap = 0;
                    if (additionalInformation.topics && additionalInformation.topics[0]) {


                        topicsSwap = additionalInformation.topics.map((item) => topicMap.get(item.toString()));
                        if (topicsSwap[0] === undefined) {
                            topicsSwap[0] = additionalInformation.topics;
                        }
                    } else {
                        topicsSwap = null;
                    }

                    let serviceDeliverySwap = 0;
                    if (additionalInformation.serviceDelivery && additionalInformation.serviceDelivery[0]) {


                        serviceDeliverySwap = additionalInformation.serviceDelivery.map((item) => serviceDeliveryTypeMap.get(item.toString()));
                        if (serviceDeliverySwap[0] === undefined) {
                            serviceDeliverySwap[0] = additionalInformation.serviceDelivery;
                        }
                    } else {
                        serviceDeliverySwap = null;
                    }

                    let categoriesTrainingSwap = additionalInformation.categoriesTraining ? additionalInformation.categoriesTraining.map((item) => categoriesTrainingMap.get(item.toString())) : null;


                    let typologyTrainingProvidersSwap = 0;
                    if (additionalInformation.typologyTrainingProviders && additionalInformation.typologyTrainingProviders[0]) {


                        typologyTrainingProvidersSwap = additionalInformation.typologyTrainingProviders.map((item) => typologyTrainingProvidersMap.get(item.toString()));
                        if (typologyTrainingProvidersSwap[0] === undefined) {
                            typologyTrainingProvidersSwap[0] = additionalInformation.serviceDelivery;
                        }
                    } else {
                        typologyTrainingProvidersSwap = null;
                    }



                    setData({
                        ...additionalInformation,
                        expertiseOn: expertiseOnSwap,
                        knowAbout: knowAboutSwap,
                        topics: topicsSwap,
                        serviceDelivery: serviceDeliverySwap,
                        categoriesTraining: categoriesTrainingSwap,
                        typologyTrainingProviders: typologyTrainingProvidersSwap,

                    });

                }
                else {
                    setData(null);
                }

            } catch (error) {
                console.log(error);
                setData(null);
            }
        }

        if (idProfile) {
            loadData();
        }

    }, [idProfile, profileMacroType]);


    return (

        data && (
            <Card sx={{ width: '100%', paddingLeft: 0, paddingRight: 0, mt: 5, boxShadow: 3 }}>

                <CardContent>

                    <Typography sx={{ mb: 2, fontSize: '28px', fontWeight: 'bold' }} >{title}</Typography>

                    <MyDividerLarge />

                    <ExpertiseBox data={data} />

                    <MyDividerLarge />

                    <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', py: 2, gap: 2 }}>
                        {data.typologyTrainingProviders && (
                            <BlockBox title={'Investor type'} data={data.typologyTrainingProviders} />
                        )}
                        {data.topics && (
                            <BlockBox title={'Topic'} data={data.topics} />
                        )}
                        {data.serviceDelivery && (
                            <BlockBox title={'Service Delivery Type'} data={data.serviceDelivery} />
                        )}
                    </Box>

                    <MyDividerSmall />

                    <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', py: 2, gap: 2 }}>
                        {data.categoriesTraining && (
                            <BlockBox title={'Training provided'} data={data.categoriesTraining} />
                        )}
                    </Box>

                </CardContent>
            </Card>
        )


    )
}
