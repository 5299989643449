import { Close, FilterAlt } from "@mui/icons-material";
import { AppBar, Box, Button, Checkbox, Dialog, DialogActions, DialogContent, Divider, FormControl, FormControlLabel, FormGroup, IconButton, Input,  Radio, RadioGroup, Slide,Toolbar, Typography } from "@mui/material";
import { forwardRef, useEffect, useState } from "react";
import { strings } from "../../strings";
import ServerProxy from "./serverProxy";

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});



export default function DialogFilter(props) {

    const { open, handleClose, filters, setFilters, profile  } = props;
    const maxWidth = 'md';

    const [regions, setRegions] = useState(null);
    const [profileTypes, setProfileTypes] = useState(null);
    const [expertise, setExpertise] = useState(null);

    const [currentFilters, setCurrentFilters] = useState(filters)


    const resetFilters = () => {
       
        // console.log('resetFilters', profile);

        const regions = profile.positions.map(item => item.region.id);
        const lookingFor = profile.lookingFor.map(item => item.id);
        const { knowAbout: expertiseOn } = profile.additionalInfo ? profile.additionalInfo : { knowAbout: [] };


        const endDate = new Date();
        endDate.setMonth(endDate.getMonth() + 1);

        setCurrentFilters({ 
            tabs : 0, 
            where : regions, 
            type : lookingFor, 
            expertise : expertiseOn, 
            profile,
            startDate: new Date().toISOString().slice(0,-8),
            endDate: endDate.toISOString().slice(0,-8)
        });


    }


    useEffect(() => {

        // const endDate = new Date();
        // endDate.setMonth(endDate.getMonth() + 1);

        setCurrentFilters({
            ...filters,
            // startDate : new Date().toISOString().slice(0,-8),
            // endDate: endDate.toISOString().slice(0,-8)
        });

    }, [filters]);


    useEffect( () => {

        async function loadData(){
            const regions = await ServerProxy.getRegions();
            setRegions(regions)

            const profileTypes = await ServerProxy.getProfileTypes()
            setProfileTypes(profileTypes);

            const expertise = await ServerProxy.getEspertiseAreas()
            setExpertise(expertise);
        }

        loadData();
        
    }, []) 
    

    //console.log( 'currentFilters',  currentFilters );

    return (

        <Dialog open={open} onClose={handleClose} TransitionComponent={Transition}

            fullWidth={true}
            maxWidth={maxWidth}

            PaperProps={{ 
                style: { 
                    backgroundColor: 'rgba(255, 255, 255, 0.9)', 
                } 
            }}

        >
            <AppBar sx={{ position: 'relative' }}>
                <Toolbar>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }} >

                        <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }} >

                            <FilterAlt />
                            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                                {strings.filters}
                            </Typography>

                        </Box>

                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <Close />
                        </IconButton>
                    </Box>
                
                </Toolbar>
            </AppBar>

            <DialogContent>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>

                

                    <Box sx={{mb: 3}}>
                        <Typography sx={{ fontSize: '1.0rem', fontWeight: 'bold', mb: 1, flex: 1 }} variant="h3">Where</Typography>

                        <Divider sx={{mb: 2}} />

                        <FormGroup sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', flexWrap: 'wrap' }}>

                            {regions && regions.map((item, index) => (
                                <FormControlLabel
                                    sx={{ mr: 2, mb:2, width: 200 }}
                                    key={index}
                                    control={
                                        <Checkbox
                                            checked={ currentFilters.where && currentFilters.where.includes(item.id)}
                                            value={item.id}
                                            onChange={(event) => {

                                                const {where} = currentFilters;
                                                const checked = event.target.checked
                                                const value = event.target.value

                                                if (checked) {
                                                    where.push(value);
                                                }
                                                else {  
                                                    const index = where.indexOf(value);
                                                    
                                                    where.splice(index, 1);

                                                }

                                                setCurrentFilters({
                                                    ...currentFilters,
                                                    where
                                                });
                                            }} />
                                    }

                                    label={item.name} />
                            ))}
                        </FormGroup>

                    </Box>

                    <Box sx={{mb: 3}}>
                        <Typography sx={{ fontSize: '1.0rem', fontWeight: 'bold', mb: 1, flex: 1 }} variant="h3">Let's match</Typography>
                        
                        <Divider sx={{mb: 2}} />

                        <FormGroup sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
                            {profileTypes && profileTypes.map((item, index) => (
                                <FormControlLabel 
                                    sx={{ mr: 2, mb:2, width: 380 }}
                                    key={index}
                                    control={
                                        <Checkbox
                                           
                                            checked={ currentFilters.type && currentFilters.type.includes(item.id.toString() )}
                                            value={item.id}
                                            onChange={(event) => {

                                                const {type} = currentFilters;
                                                const checked = event.target.checked
                                                const value = event.target.value

                                                if (checked) {
                                                    type.push(value);
                                                }
                                                else {  
                                                    const index = type.indexOf(value);
                                                    type.splice(index, 1);
                                                }

                                                setCurrentFilters({
                                                    ...currentFilters,
                                                    type
                                                });
                                            }} />
                                    }

                                    label={item.name} />
                            ))}
                        </FormGroup>
                    </Box>

                    <Box sx={{mb: 3}}>
                        <Typography sx={{ fontSize: '1.0rem', fontWeight: 'bold', mb: 1, flex: 1 }} variant="h3">Expertise on</Typography>
                        
                        <Divider sx={{mb: 2}} />

                        <FormGroup sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
                            {expertise && expertise.map((item, index) => (
                                <FormControlLabel 
                                    sx={{ mr: 2, mb:2, width: 380 }}
                                    key={index}
                                    control={
                                        <Checkbox
                                            checked={ currentFilters.expertise && currentFilters.expertise.includes(item.id.toString() )}
                                            value={item.id}
                                            onChange={(event) => {

                                                const {expertise} = currentFilters;
                                                const checked = event.target.checked
                                                const value = event.target.value

                                                if (checked) {
                                                    expertise.push(value);
                                                }
                                                else {  
                                                    const index = expertise.indexOf(value);
                                                    
                                                    expertise.splice(index, 1);

                                                }

                                                setCurrentFilters({
                                                    ...currentFilters,
                                                    expertise
                                                });
                                            }} />
                                    }

                                    label={item.name} />
                            ))}
                        </FormGroup>
                    </Box>

                    <Box sx={{mb: 3}}>
                        <Typography sx={{ fontSize: '1.0rem', fontWeight: 'bold', mb: 1, flex: 1 }} variant="h3">Events</Typography>
                        
                        <Divider sx={{mb: 2}} />
                       
                        <Box sx={{display: 'flex', flexDirection: 'column' }}> 
                            
                            <Box sx={{display:'flex', justifyContent: "flex-start" }}>

                                <FormControl sx={{mr:3}} >
                                    <Typography variant="caption">Start date</Typography>
                                    <Input type="datetime-local"  
                                        onChange={(event) => {
                                            const value = event.target.value;

                                            //console.log(value);
                                            setCurrentFilters({
                                                ...currentFilters,
                                                startDate: value
                                            });

                                        }} 
                                        value={currentFilters.startDate ?? new Date().toISOString().slice(0,-8) }  />
                                </FormControl>

                                <FormControl>
                                
                                    <Typography variant="caption">End date</Typography>

                                    <Input type="datetime-local"  
                                        onChange={(event) => {
                                            const value = event.target.value;

                                            setCurrentFilters({
                                                ...currentFilters,
                                                endDate: value
                                            });
                                        }} 
                                        value={ currentFilters.endDate ?? new Date().toISOString().slice(0,-8) }  />

                                </FormControl>
                            </Box>
                        </Box>
                    </Box>

                </Box>
            </DialogContent>
            <DialogActions>
                                    
                <Box sx={{display: 'flex', justifyContent: 'space-between', width: '100%'  }}>
                  
                    <Button onClick={resetFilters}>{strings.resetFilters}</Button>
                    <Button onClick={ () => { 
                        setFilters({...currentFilters});  
                        handleClose();
                    }}>{strings.apply}</Button>
                   
                </Box>

                

            </DialogActions>
        </Dialog>
    )

}

