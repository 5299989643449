
import { Box, Card, CardContent, Divider, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import ExpertiseBox from "./ExpertiseBox";
import ProfileDetailBox from "./ProfileDetailBox";
import BlockBox from "./BlockBox";
import ServerProxy from "../tools/serverProxy";
import { MyDividerLarge, MyDividerSmall } from "./MyDivider";


export default function IncubatorsAcceleratorsCard(props) {
    const { title, idProfile } = props;
    const [data, setData] = useState(null);

    useEffect(() => {

        const loadData = async () => {

            const additionalInformation = await ServerProxy.getAdditionalInformation(idProfile);
            if (additionalInformation) {

                const expertiseOnMap = new Map();

                const espertiseAreas = await ServerProxy.getEspertiseAreas();
                for (const item of espertiseAreas) {
                    expertiseOnMap.set(item.id, item.name);
                }


                const industryMap = new Map();

                const companyFocus = await ServerProxy.getCompanyFocus();
                for (const item of companyFocus) {
                    industryMap.set(item.id, item.name);
                }

                const serviceMap = new Map();

                const service = await ServerProxy.getService();
                for (const item of service) {
                    serviceMap.set(item.id, item.name);
                }

                const incubatorsTypeMap = new Map();

                const incubatorsType = await ServerProxy.getIncubatorsType();
                for (const item of incubatorsType) {
                    incubatorsTypeMap.set(item.id, item.name);
                }

                let expertiseOnSwap= additionalInformation.expertiseOn ? additionalInformation.expertiseOn.map((item) => expertiseOnMap.get(item.toString())) : null;
                let knowAboutSwap = additionalInformation.knowAbout ? additionalInformation.knowAbout.map((item) => expertiseOnMap.get(item.toString())) : null;
                
                console.log(additionalInformation);


                let servicesSwap =0;
                if(additionalInformation.services && additionalInformation.services[0]){
                    servicesSwap= additionalInformation.services.map((item) => serviceMap.get(item.toString()));
                    if(servicesSwap[0] === undefined){
                        servicesSwap[0] = additionalInformation.services;
                    }
                }else{
                    servicesSwap = null;
                }


                let incubatorsTypeSwap =0;
                if(additionalInformation.incubatorsType && additionalInformation.incubatorsType[0]){
                    incubatorsTypeSwap= additionalInformation.incubatorsType.map((item) => incubatorsTypeMap.get(item.toString()));
                    if(incubatorsTypeSwap[0] === undefined){
                        incubatorsTypeSwap[0] = additionalInformation.incubatorsType;
                    }
                }else{
                    incubatorsTypeSwap = null;
                }

                setData({
                    ...additionalInformation,
                    expertiseOn : expertiseOnSwap,
                    knowAbout : knowAboutSwap,
                    service : servicesSwap,
                    incubatorsType : incubatorsTypeSwap,

                    //services : null
                });

            }
            else {
                setData(null);
            }

        }


        if (idProfile) {
            loadData();
        }

    }, [idProfile]);


    return (

        data && (
            <Card sx={{ width: '100%', paddingLeft: 0, paddingRight: 0, mt: 5, boxShadow: 3 }}>

                <CardContent>

                    <Typography sx={{ mb: 2, fontSize: '28px', fontWeight: 'bold' }} >{title}</Typography>

                    <MyDividerLarge />

                    {data && <ExpertiseBox data={data} />}

                    <MyDividerLarge />

                    {data.typology && 
                        <ProfileDetailBox data={[
                            {
                                label: 'Typology',
                                value: data.typology.name,
                            }
                        ]} />
                    }

                    <MyDividerSmall />

                    <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', py: 2, gap: 2 }}>
                        { data.service && <BlockBox title={'Services offered'} data={data.service} />}
                        { data.incubatorsType && <BlockBox title={'Type of incubators/accelerators'} data={data.incubatorsType} />}
                    </Box>

                    <MyDividerSmall />

                    <ProfileDetailBox data={[
                        {
                            label: 'Founded',
                            value: data.founded,
                            sx: { mb: 2 }
                        },
                        {
                            label: 'More about network, resources and initiatives',
                            value: data.shortDescription,
                        }

                    ]} />

                </CardContent>
            </Card>
        )
    )
}