import { Box, Button, IconButton, Typography, rgbToHex } from "@mui/material";
import { Close } from "@mui/icons-material";
import BorderLinearProgress from './BorderLinearProgress';



export default function DialogCheckProfile(props) {

    return (

        <Box sx={{ 
            width: '100%', height: '100%', 
            position: 'absolute', bgcolor: '#666', opacity: '.9', zIndex:'9999',
            display: props.open ? 'flex' : 'none'  , 
            justifyContent: { xs: 'center', lg: 'flex-end'},
            alignItems: { xs: 'center', lg: 'flex-end'},
        }} >

            <Box sx={{ 
                width: '320px', 
              
                border: 3,
                borderImage: 'linear-gradient(to right, rgba(192, 8, 127, 0.7) 0%, rgba(192, 8, 127, 0.7) 22%,rgba(236, 227, 20, 0.7) 100%)',
                borderImageSlice: 1,
                borderRadius: '5px',
                backgroundColor: 'rgba(255, 255, 255, .9)',
                opacity: '1',
                color: '#000',

                mr: { xs: 0, lg: 2}, 
                mb: { xs: 0, lg: 2},

                display: 'flex' ,
                flexDirection: 'column'

                }}>
                
                <Box sx={{ 
                    width: '100%', 
                    display: 'flex' , 
                    justifyContent: 'flex-end', 
                    alignItems: 'center',
                    p: 0,
                    m: 0
                    }}>
                    <IconButton aria-label="close" onClick={props.handleClose} size="small">
                        <Close fontSize="inherit" />
                    </IconButton>
                </Box>

                <Box sx={{pb:3}}>

                    <Box sx={{ 
                        width: '100%', 
                        display: 'flex' , 
                        justifyContent: 'center', 
                        alignItems: 'center',
                        p: 0
                        }}>

                        <Box component='img' sx={{width:48}} src="/notifications.gif" alt="Notifications"  />
                    </Box>        

                    <Typography variant="h6" component="div" sx={{ 
                        width: '100%', 
                        display: 'flex' , 
                        justifyContent: 'center', 
                        alignItems: 'center',
                        p: 1,
                        mb: 1
                        }}>
                        Complete your profile now!
                    </Typography>    
                    

                    <Box sx={{ 
                        width: '100%', 
                        display: 'flex' , 
                        justifyContent: 'center', 
                        alignItems: 'center',
                        p: 0
                        }}>

                        <BorderLinearProgress length={8} sx={{width: '80%'}} variant="determinate" value={75} />
                    </Box>        

                    <Typography variant="body2" component="div" sx={{ 
                        width: '100%', 
                        display: 'flex' , 
                        justifyContent: 'center', 
                        alignItems: 'center',
                        p: 2,
                        px: 3  
                        }}>
                        Specific information about your profile will be used for an even more accurate match among actors in the platform.
                    </Typography> 
                    

                    <Box sx={{ 
                        width: '100%', 
                        display: 'flex' , 
                        justifyContent: 'center', 
                        alignItems: 'center',
                        p: 0
                        }}>

                        <Button variant="contained" onClick={props.onGoNow}>{`Go now >`}</Button>
                    </Box>     
                </Box>

            </Box>


        </Box>
    )
}