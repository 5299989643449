import { ArrowLeft, ArrowRight, Clear } from '@mui/icons-material';
import { Box, Button, Typography, useMediaQuery } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';


const helpContent = [
    {
        title : 'The matchmaking map',
        content : `Welcome to the matchmaking map, the tool that uses an innovative algorithm to find interesting profiles for you in Europe and worldwide!
                    The map gathers all users registered within the DeepTech ACT platform and located according to their geographical work location.`,
    },
    {
        title : 'Your matching list!',
        content : `The leftside bar presents all the users within the matching map ordered by your interest. 
                    <br><br>Users who best match your interests will be at the top of the list and coloured in purple.`,
    },
    {
        title : 'Your toolbar',
        content : `The tool bar gathers 4 different functionalities.`,
    },
    {
        title : 'Search',
        content : `It allows you to search for specific people registered on the platform by name and surname.`,
    },
    {
        title : 'Best matches',
        content : `It gives you the possibility to always see your 3 best matches, updated as new users are added to the platform.`,
    },
    {
        title : 'Manual filters',
        content : `Filters, on the other hand, allow you to manually search for users based on your specific interests and needs.`,
    },
    {
        title : 'Help',
        content : `Finally, the help section helps you, at any time, to move around the platform.`,
    },
    {
        title : 'Your matching list',
        content : `The matching list contains your favourites and the profiles that are most interesting for your search. In general, these are the profiles who have been marked in the matching list with a star.`,
    },
    {
        title : ' Your profile',
        content : `This section allows you to go back to your profile and to change profile, if you are registered with different profiles.`,
    }
]


export default function HelpPanel(props) {

    const {open} = props;  


	const theme = useTheme();

    const isSmallScreenPredefined = useMediaQuery(theme.breakpoints.down('md'));
    const isDownLG = useMediaQuery(theme.breakpoints.down('lg'));  
    const isLargeScreenPredefined = useMediaQuery(theme.breakpoints.up('lg'));  


    const onClose = () => {
        props.setHelpPanel(0);
    }


    const Root = styled(Box)(({ theme }) => ({
        width: '100%',
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'rgba(0, 0, 0, .9)',  
        zIndex: 2000,
        //zIndex: -1,
    }));



    function MyModal (props){

        //const theme = useTheme();   

        const {title, content} = helpContent[props.open - 1];

        const onContinue = () => {

            if (props.open === helpContent.length){
                props.onClose();
                return;
            }

            if (props.open + 1 === 2 && props.setLeftOpen !== undefined){
                props.setLeftOpen(true);

                if (!isLargeScreenPredefined) {
                    props.setRightOpen(false)
                }
            }
            else if (props.open + 1 === 8 && props.setRightOpen !== undefined){
                props.setRightOpen(true);

                if (!isLargeScreenPredefined) {
                    props.setLeftOpen(false)
                }
            }

            props.setHelpPanel(props.open + 1)

        }

        const onBack = () => {
            props.setHelpPanel(props.open - 1)
        }
        

     
        const position = {}

        if (!isSmallScreenPredefined){
            switch  (props.open){
                case 2:
                    if (isDownLG)
                        position.right = 50;
                    break;
    
                case 3:
                case 4: 
                case 5:
                case 6:
                case 7:
                    if (isDownLG)
                        position.bottom = 50;

                    break;
                case 8:
                    if (isDownLG)
                        position.left = 50;

                    break;
            }
        }

        else{
            switch  (props.open){
                case 2:
                case 8: 
                    if (isDownLG)   
                        position.top = 5;
                    break;

                case 3:
                case 4:
                case 5:
                case 6:
                case 7:
                    position.bottom = 5;
                    break;

    
                // case 8:
                //     position.left = 50;
                //     break;
            }
        }


        return (

            <Box maxWidth='sm' sx={{
                position: 'absolute',
                width: { xs: '96%', sm: 'auto' }, 
                mx : 'auto', 
                height: 'auto',
                backgroundColor: 'rgba(255, 255, 255, 1)',
                borderRadius: 2,
                border : `1px solid ${theme.palette.primary.main}`, 
                pointerEvents: 'auto',  
                zIndex: 9999,
                p: 2,
                ...position

            }}>
                <Box sx={{position: 'relative', width: '100%', height: '100%' }}>

                    { (props.open > 1) && 
                        <Box sx={{
                            position: 'absolute', 
                            width: 24,
                            height: 24,
                            backgroundColor: theme.palette.primary.main,
                            color: '#fff',
                            borderRadius: '50%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            fontSize: '.8rem',
                            fontWeight: 'bold',
                            top: 0, 
                            right: 0,

                        }}>{props.open - 1}</Box>
                    }

                    <Box sx={{mb:3}}>
                        <Typography variant="h6" component="div" sx={{flexGrow: 1, mb: 2 }}>
                            {title}
                        </Typography>

                        <Typography variant="body" component="div" sx={{flexGrow: 1}}>
                            <Box component="div" dangerouslySetInnerHTML={{ __html: content }}></Box>
                        </Typography>
                    </Box>

                    <Box sx={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center', mt: 2, flexWrap: 'wrap' }}>
                        
                        { (props.open > 1) && 

                        <Button sx={{width: { xs:'100%', sm: 'auto'} , mb: 1, mr: { xs: 0, sm : 1 }}} variant="contained" color="primary" onClick={ onBack }><ArrowLeft /> Click to go back</Button> }
                        <Button sx={{width: { xs:'100%', sm: 'auto'} , mb: 1, mr: { xs: 0, sm : 1 }}} variant="contained" color="primary" onClick={ onContinue }>Click to continue <ArrowRight /></Button>
                        <Button sx={{width: { xs:'100%', sm: 'auto'} , mb: 1, mr: { xs: 0, sm : 1 }}} variant="contained" color="primary" onClick={props.onClose}>Skip tutorial </Button>
                    </Box>
                </Box>

            </Box>
        )
    }




    return (
        <>
            {open !== 0 &&
                <Root >
                    <MyModal {...props} onClose={onClose} />
                </Root>
            }
        </>

    )

}