const config = {
    server : {

        protocol : process.env.NODE_ENV !== 'production' ? 'http' : 'https',
        host: process.env.NODE_ENV !== 'production' ? 'localhost' :  'virtualecosystem-map.acceleraction.eu',
        port: process.env.NODE_ENV !== 'production' ? '5050' : '443',
        prefix : process.env.NODE_ENV !== 'production' ? '/api/v1.0' : '/api/v1.0',

        // protocol : 'https',
        // host: 'virtualecosystem-map.acceleraction.eu',
        // port: '443',
        // prefix : '/api/v1.0',

        auth : {
            username :  process.env.REACT_APP_USERNAME,
            password : process.env.REACT_APP_PASSWORD,
        },

        backetUrl : 'https://ve-acceleraction-assets.s3.eu-south-1.amazonaws.com'
    },
  
    VES_URL : 'https://deeptechact.acceleraction.eu/secure/account',
    mapboxgl_accessToken : process.env.REACT_APP_MAPBOXGL_TOKEN,
    avatarSize : 72,

    accelerActionUrl : 'https://deeptechact.acceleraction.eu',

    drawerWidth : 300,
    headerHeight : 285,

    bestMatchThreshold : 70
};

export default config;
