export function AccIcon(props) {

    const {fill = "#c0087f"} = props;

    return (
        <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M1.25432 20.7345L3.25763 16.3674C3.54702 15.7341 3.77871 15.0798 3.93439 14.4227C4.41966 12.4403 4.32654 10.3702 3.64587 8.3861C3.54423 8.07944 3.42803 7.78017 3.29734 7.48107L0.867178 1.92424C0.347924 0.738651 2.01345 -0.239207 3.30888 0.499719L16.6338 8.09854L16.6411 8.09846L21.3406 10.777C22.2725 11.3136 22.2859 12.4033 21.3644 12.895L16.8661 15.2945L3.71751 22.2971C2.42455 22.9812 0.723682 21.9027 1.25432 20.7345ZM9.72187 13.9861L12.6794 12.4111L13.6884 11.8677C13.896 11.7605 13.8951 11.5106 13.6827 11.3935L12.6248 10.7895L9.62732 9.08078C9.33585 8.9138 8.9605 9.13156 9.07702 9.40185L9.62453 10.6523C9.65515 10.722 9.68096 10.7893 9.70197 10.8542C9.85638 11.3015 9.8766 11.7684 9.76668 12.215C9.73246 12.3638 9.68017 12.5093 9.61705 12.6512L9.1645 13.6338C9.04575 13.8958 9.43049 14.1414 9.72187 13.9861Z" fill={fill}/>
        </svg>
    )
}

export function Asterisk(props) {
    
    const {fill = "#c0087f"} = props;

    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 6V18" stroke={fill} stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M17.1957 9L6.80371 15" stroke={fill} stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M6.80371 9L17.1957 15" stroke={fill} stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    )
}
