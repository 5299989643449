
import { useEffect, useState } from 'react';

import { Box, Button, CircularProgress, Dialog, DialogContent, DialogTitle, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import TooltipContentProfile from './TooltipContentProfile';
import ServerProxy from './serverProxy';


export default function BestMatchDialog(props) {

    const {profile, open , handleClose} = props;
    const [data, setData] = useState(null);   


    const theme = useTheme();
    const fullWidth = useMediaQuery(theme.breakpoints.down('md'));


    useEffect(() => {
     
        const loadData = async () => {

			try {

                const profiles = await ServerProxy.getProfiles({ ids: profile.lastMatch.map((item) => item.id) });

                if (profiles)    {
                    setData(profiles.map((item) => (
                        {
                            ...item,
                            match: profile.lastMatch.find((match) => match.id === item.id).match
                        }
                    )));
                }
                else{
                    setData([]);
                }

			} catch (error) {

				console.log(error);
				setData([]);
			}
        }


        if (open && profile.lastMatch){
            loadData();
        }
        else{
            handleClose();
        }

    }, [profile, open]);


    if (!open){
        return null;
    }



    return (
        <>
            <Dialog fullWidth={true} maxWidth={fullWidth ? 'xs' : 'md'} 
                open={open}
                onClose={handleClose}
                PaperProps={{
                    style: { backgroundColor: 'rgba(255,255,255, .9)' }, 
                }}
               
            >
                <DialogTitle >
                    <Box sx={{display: 'flex' , justifyContent: 'center', flexDirection: 'column', py: 2 }}>
                        <Typography sx={{ textAlign: 'center', fontWeight: 'bold', mb: 1 }} variant="h4" color="text.primary">Your top matches this month!</Typography>
                        <Typography sx={{ textAlign: 'center',  }} variant="body1" color="text.secondary">We have chosen some profiles with a high match rate that may interest you.</Typography>
                    </Box>
                   
                </DialogTitle>
                
                <DialogContent>

                    <Box sx={{display: 'flex' , justifyContent: 'center', flexDirection: 'column' }}>

                        <Stack 
                            useFlexGap
                            direction={{ xs: 'column', md: 'row' }}
                            spacing={{ xs: 1, sm: 2 }}
                            sx={{mb: 4}} >
                                
                            {data ? data.map((item, index) => (

                                <Box key={index}
                                 sx={{
                                    p: 1,
                                    mb: 1,
                                    width: {xs : '100%', md: '320px'},
                                    border: 3,
                                    borderImage: 'linear-gradient(to right, rgba(192, 8, 127, 0.7) 0%, rgba(192, 8, 127, 0.7) 22%,rgba(236, 227, 20, 0.7) 100%)',
                                    borderImageSlice: 1,
                                    borderRadius: '5px',
                                    backgroundColor: '#eee',
                                    color: '#000'
                                }} >
                                    <TooltipContentProfile key={item.id} profile={item} onClick={handleClose} />
                                </Box>

                            )) : 

                            <Box sx={{display: 'flex', justifyContent:'center', alignItems: 'center', width: '100%', height: '100%', p : 5}}>
                                <CircularProgress />
                            </Box>
                        
                        }


                        </Stack>

                        <Box sx={{display: 'flex', justifyContent:'center', mb: 2}} >
                            <Button variant='contained' onClick={handleClose} autoFocus>
                                Close
                            </Button>
                        </Box>
                        
                    </Box>

                </DialogContent>
            
            </Dialog>
        </>
    );
}