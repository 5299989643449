import { Box } from "@mui/material";


export default function BoxSx(props) {
    return (
      <Box
        sx={{
            width: 'auto',
            height: 'auto',
            border: 'none',
            padding: '1px 8px',
            textAlign: 'center',
            textDecoration: 'none',
            display: 'inline-block',
            mr: 1,  
            mb: 1,
            backgroundImage: 'linear-gradient(to left, rgba(192, 8, 127, 0.7) 0%, rgba(192, 8, 127, 0.7) 22%,rgba(236, 227, 20, 0.7) 100%)',
            color: 'primary.contrastText',
            borderRadius: '16px',
            fontSize: '0.7rem',

            '&:hover': {
                backgroundColor: 'primary.main',
                opacity: [0.9, 0.8, 0.7]
            },
        }}

        title={props.children}
      >
        {props.children}</Box>
    );
}
