
import { Box, Card, CardContent, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import ExpertiseBox from "./ExpertiseBox";
import ProfileDetailBox from "./ProfileDetailBox";
import BlockBox from "./BlockBox";
import ServerProxy from "../tools/serverProxy";
import { MyDividerLarge, MyDividerSmall } from "./MyDivider";


export default function InvestorCard(props) {

    const { title = 'Investor', idProfile } = props;
    const [data, setData] = useState(null);


    useEffect(() => {

        const loadData = async () => {


            const additionalInformation = await ServerProxy.getAdditionalInformation(idProfile);
            if (additionalInformation) {

                const expertiseOnMap = new Map();

                const espertiseAreas = await ServerProxy.getEspertiseAreas();
                for (const item of espertiseAreas) {
                    expertiseOnMap.set(item.id, item.name);
                }


                const industryMap = new Map();

                const companyFocus = await ServerProxy.getCompanyFocus();
                for (const item of companyFocus) {
                    industryMap.set(item.id, item.name);
                }

                const investorTypeMap = new Map();

                const investorType = await ServerProxy.getInvestorType();
                for (const item of investorType) {
                    investorTypeMap.set(item.id, item.name);
                }


                const activityLevelMap = new Map();

                const activityLevel = await ServerProxy.getActivityLevel();
                for (const item of activityLevel) {
                    activityLevelMap.set(item.id, item.name);
                }

                let investorTypeSwap = 0;
                if (additionalInformation.investorType && additionalInformation.investorType[0]) {


                    investorTypeSwap = additionalInformation.investorType.map((item) => investorTypeMap.get(item.toString()));
                    if (investorTypeSwap[0] === undefined) {
                        investorTypeSwap[0] = additionalInformation.investorType;
                    }
                } else {
                    investorTypeSwap = null;
                }


                let expertiseOnSwap = additionalInformation.expertiseOn ? additionalInformation.expertiseOn.map((item) => expertiseOnMap.get(item.toString())) : null;
                let knowAboutSwap = additionalInformation.knowAbout ? additionalInformation.knowAbout.map((item) => expertiseOnMap.get(item.toString())) : null;
                let industrySwap = additionalInformation.industry ? additionalInformation.industry.map((item) => industryMap.get(item.toString())) : null;
                let activityLevelSwap = additionalInformation.activityLevel ? additionalInformation.activityLevel.map((item) => activityLevelMap.get(item.toString())) : null;


                setData({
                    ...additionalInformation,
                    expertiseOn: expertiseOnSwap,
                    knowAbout: knowAboutSwap,
                    industry: industrySwap,
                    investorType: investorTypeSwap,
                    activityLevel: activityLevelSwap,
                });

            }
            else {
                setData(null);
            }

        }


        if (idProfile) {
            loadData();
        }

    }, [idProfile]);


    //console.log(data);


    return (


        data && (
            <Card sx={{ width: '100%', paddingLeft: 0, paddingRight: 0, mt: 5, boxShadow: 3 }}>

                <CardContent>

                    <Typography sx={{ mb: 2, fontSize: '28px', fontWeight: 'bold' }} >{title}</Typography>


                    <MyDividerLarge />
                   
                        <ExpertiseBox data={data} />
                   
                    <MyDividerLarge />

                    <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', py: 2, gap: 2 }}>
                        {data.investorType && (
                            <BlockBox sx={{ py: { xs: 1 } }} title={'Investor type'} data={data.investorType} />
                        )}
                        {data.activityLevel && (
                            <BlockBox sx={{ py: { xs: 1 } }} title={'Activity level'} data={data.activityLevel} />
                        )}
                        {data.industry && (
                            <BlockBox sx={{ py: { xs: 1 } }} title={'Industry focus'} data={data.industry} />
                        )}
                    </Box>

                    <MyDividerSmall />

                    <ProfileDetailBox data={[
                        {
                            label: 'Portfolio companies',
                            value: data.portfolio,
                        }
                    ]} />

                </CardContent>
            </Card>
        )


    )
}
