import { ArrowRightAlt, Email, LinkedIn, Place } from "@mui/icons-material";
import { Avatar, Box, Divider, IconButton, Link, ListItemAvatar, ListItemText, Tooltip, Typography } from "@mui/material";
import BorderLinearProgress from "./BorderLinearProgress";
import config from "../../config";
import { Link as RouterLink,  useNavigate } from "react-router-dom";
import { grey } from "@mui/material/colors";


export default function TooltipContentProfile(props) {

    const { profile, onClick} = props;
    const navigate = useNavigate();


    return (
        <>
            
            <Box onClick={ () => { if(onClick){onClick()}; navigate('/user/' + profile.user.id + '/profile/' + profile.id) }} sx={{ cursor: 'pointer',  width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', mb: 1 }}>
                <ListItemAvatar sx={{mr: 2}}>
                    <Avatar sx={{ width: 56, height: 56 }} alt={profile.user.firstname + ' ' + profile.user.lastname}
                        src={profile.user.avatarUrl ? config.server.backetUrl + profile.user.avatarUrl : null}>{profile.user.firstname.charAt(0)}</Avatar>
                </ListItemAvatar>

                <ListItemText
                    sx={{ flexGrow: 1 }}
                    primary={<Typography sx={{ fontSize: '.9rem', fontWeight: 'bold' }} variant="h6">{profile.user.firstname + ' ' + profile.user.lastname}</Typography>}
                    secondary={<Typography sx={{ fontSize: '.8rem'}} variant="button" display="block" gutterBottom color="text.primary">{profile.profileMacroType.name}</Typography>}
                />
            </Box>
            

            <Divider sx={{ mb: 1 }} />


            <Box sx={{ mb: 2, height: '80px', overflow: 'auto'}}>
                <Typography sx={{color: grey[900]}} variant="caption">{profile.shortDescription ?? ''}</Typography>
            </Box>

            <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', mb: 2 }}>
                <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <Place color="primary" sx={{ mr: 1 }} /> <Typography variant="body2" >{profile.positions[0].place}</Typography>
                </Box>
            </Box>

            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Typography sx={{ fontSize: '1rem', textAlign: 'center', fontWeight: 'bold' }} variant="button" >match score</Typography>
            </Box>

            <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', mb: 2 }}>
                <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative' }}>
                    <Typography sx={{ position: 'absolute', zIndex: '9999', fontWeight: 'bold' }} variant="body2" color="white">{profile.match}%</Typography>
                    <BorderLinearProgress sx={{ width: '100%' }} variant="determinate" value={profile.match} />
                </Box>
            </Box>


            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                {profile.user.email && <IconButton component={Link} target="_blank" href={`mailto:${profile.user.email}`} size="small"><Tooltip title={profile.user.email}><Email fontSize="inherit" color="primary" /></Tooltip></IconButton>}
                {profile.user.linkedInUrl && <IconButton component={Link} target="_blank" href={profile.user.linkedInUrl} size="small"><LinkedIn fontSize="inherit" color="primary" /></IconButton>}

                <IconButton size="small" onClick={ () => { if(onClick){onClick()}; navigate('/user/' + profile.user.id + '/profile/' + profile.id) }}><ArrowRightAlt fontSize="inherit" color="primary" /></IconButton>
            </Box>
        </>
    )
}