
import { styled } from '@mui/material/styles';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';



export default function BorderLinearProgress(props){

    const {sx, value, length = 26} = props;

    const BLP = styled(LinearProgress)(({ theme }) => ({
        height: length,
        borderRadius: length / 2,
        [`&.${linearProgressClasses.colorPrimary}`]: {
          backgroundColor: theme.palette.primary.moreDark,
        },
        [`& .${linearProgressClasses.bar}`]: {
          borderRadius: length / 2,
          backgroundImage: 'linear-gradient(to right, rgba(192, 8, 127, 0.7) 0%, rgba(192, 8, 127, 0.7) 22%,rgba(236, 227, 20, 0.7) 100%)',
        },
    }));

    return (
        <BLP sx={{...sx}} variant="determinate" value={value} />
    );
}