import * as React from 'react';
import PropTypes from 'prop-types';
import CircularProgress, { circularProgressClasses } from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';

export default function CircularProgressWithLabel(props) {

	const theme = useTheme();

	const {bestMatch = false, size = 5.5 } = props;


	const [progressSize, setProgressSize] = React.useState(`${size}rem`);

	React.useEffect	(() => {
		setProgressSize(`${size}rem`);
	}, [size]);


	return (
		<Box sx={{ position: 'relative', display: 'inline-flex' }}>

			<CircularProgress sx={{
				
			}}
			
			size={progressSize} thickness={5} 
			variant="determinate" 
			value={props.value} 
			color={ bestMatch ? 'secondary' : 'primary'} 
			/>
			
			<Box
				sx={{
					top: 0,
					left: 0,
					bottom: 0,
					right: 0,
					position: 'absolute',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
				}}
			>
				<Typography variant="caption" component="div" 
					//color="" 
					color={ bestMatch ? 'white' : 'text.secondary'}

					sx={{fontSize: (4*size) - 2 , fontWeight: 'bold' }}>
					{`${Math.round(props.value)}%`}
				</Typography>
			</Box>
		</Box>
	);
}

CircularProgressWithLabel.propTypes = {
	/**
	 * The value of the progress indicator for the determinate variant.
	 * Value between 0 and 100.
	 * @default 0
	 */
	value: PropTypes.number.isRequired
};

// export default function CircularWithValueLabel() {
//   const [progress, setProgress] = React.useState(10);

//   React.useEffect(() => {
//     const timer = setInterval(() => {
//       setProgress((prevProgress) => (prevProgress >= 100 ? 0 : prevProgress + 10));
//     }, 800);
//     return () => {
//       clearInterval(timer);
//     };
//   }, []);

//   return <CircularProgressWithLabel value={progress} />;
// }