import axios from "axios";
import React, { useEffect, useMemo, useState } from "react"
import { Link as LinkRouter , Navigate, Route, Routes, useNavigate, useParams } from 'react-router-dom';

import { AppBar, Box, Button, Card, CardContent, CssBaseline, Divider, Drawer, Grid, Icon, IconButton, Link, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Skeleton, Stack, TextField, Toolbar, Typography } from "@mui/material";
import { Home, Event, Edit, ContentPasteOff, Logout, ContentPaste, CleaningServices, Delete, ExitToApp, Autorenew, CheckCircleOutline, NotInterested, MoreHoriz } from "@mui/icons-material";


import config from "../../config";

import { GridEvents } from "./GridEvents";
import CardEvent from "./CardEvent";



//NomeUtente nomeEvento place 
const url = config.server.protocol + '://' + config.server.host + ':' + config.server.port + config.server.prefix;

let token = JSON.parse(sessionStorage.getItem('token'));


const buttonOpen = (id) => (
    <Link to={`/event/${id}`}>
        <Button sx={{ color: 'white', backgroundColor: '#B91D8B' }} variant="contained" startIcon={<ContentPaste className="white" />}>
            <Typography component="span" className="Cabin Regular size16px">Apri</Typography>
        </Button>
    </Link>
);
const handleClickLogout = () => {
    sessionStorage.clear();
    window.location.reload();
};



// const ListValueEvent = (props) => {
//     const navigate = useNavigate();
//     const { eventsUser } = props;
//     const obj = ((eventsUser.length !== 0) ? eventsUser[0] : []);
//     const [evento, setEvent] = useState('');


//     useEffect(() => {
//         setEvent({ ...obj, id: obj.id, name: obj.name, place: obj.place, description: obj.description, fee: obj.fee, url: obj.url, firstnameUser: obj.firstnameUser, lastnameUser: obj.lastnameUser, status: obj.status, date_at: obj.date_at })
//     }, [obj]);



//     const handleClickEdit = async () => {

//         if (token) {
//             const { exp } = token;

//             const dateToCheck = new Date(exp * 1000) - new Date();

//             if (dateToCheck < 0) {
//                 sessionStorage.clear();
//                 window.location.reload();
//             }
//             else if (dateToCheck < (1 * 60 * 1000)) {

//                 const response = await axios.post(
//                     url + '/oauth/access_token/refresh',
//                     {
//                         grant_type: "refresh_token",
//                         refresh_token: token.token
//                     }, {
//                     headers: {
//                         'Content-Type': 'application/json',
//                     }
//                 });


//                 if (response.data) {

//                     token = response.data;
//                     token.ctime = new Date().getTime();

//                     sessionStorage.setItem('token', JSON.stringify(token));
//                 }

//             }
//         } else {
//             alert('No token found');
//             sessionStorage.clear();
//             window.location.reload();
//         }





//         try {
//             const response = await axios.patch(
//                 url + '/events/' + evento.id,
//                 {
//                     id: evento.id,
//                     name: evento.name,
//                     place: evento.place,
//                     description: evento.description,
//                     date_at: evento.date_at,
//                     fee: evento.fee,
//                     url: evento.url,
//                     firstnameUser: evento.firstnameUser,
//                     lastnameUser: evento.lastnameUser
//                 },
//                 {
//                     //params: { region, type, sector }, 
//                     headers: {
//                         'Content-Type': 'application/json',
//                         'Authorization': `Bearer ${token.token}`
//                     }
//                 }
//             );

//             window.location.reload();

//         } catch (error) {
//             console.log(error);
//         }
//     }

//     const handleClickHide = async () => {

//         if (token) {
//             const { iat, exp } = token;

//             const dateToCheck = new Date(exp * 1000) - new Date();

//             if (dateToCheck < 0) {
//                 sessionStorage.clear();
//                 window.location.reload();
//             }
//             else if (dateToCheck < (1 * 60 * 1000)) {

//                 const response = await axios.post(
//                     url + '/oauth/access_token/refresh',
//                     {
//                         grant_type: "refresh_token",
//                         refresh_token: token.token
//                     }, {
//                     headers: {
//                         'Content-Type': 'application/json',
//                     }
//                 });


//                 if (response.data) {

//                     token = response.data;
//                     token.ctime = new Date().getTime();

//                     sessionStorage.setItem('token', JSON.stringify(token));
//                 }

//             }
//         } else {
//             alert('No token found');
//             sessionStorage.clear();
//             window.location.reload();
//         }





//         try {
//             const response = await axios.patch(
//                 url + '/events/' + evento.id,
//                 {
//                     status: 0

//                 },
//                 {
//                     //params: { region, type, sector }, 
//                     headers: {
//                         'Content-Type': 'application/json',
//                         'Authorization': `Bearer ${token.token}`
//                     }
//                 }
//             );

//             navigate('/admin');

//         } catch (error) {
//             console.log(error);
//         }
//     }


//     const handleClickShow = async () => {

//         if (token) {
//             const { iat, exp } = token;

//             const dateToCheck = new Date(exp * 1000) - new Date();

//             if (dateToCheck < 0) {
//                 sessionStorage.clear();
//                 window.location.reload();
//             }
//             else if (dateToCheck < (1 * 60 * 1000)) {

//                 const response = await axios.post(
//                     url + '/oauth/access_token/refresh',
//                     {
//                         grant_type: "refresh_token",
//                         refresh_token: token.token
//                     }, {
//                     headers: {
//                         'Content-Type': 'application/json',
//                     }
//                 });


//                 if (response.data) {

//                     token = response.data;
//                     token.ctime = new Date().getTime();

//                     sessionStorage.setItem('token', JSON.stringify(token));
//                 }

//             }
//         } else {
//             alert('No token found');
//             sessionStorage.clear();
//             window.location.reload();
//         }


//         try {
//             const response = await axios.patch(
//                 url + '/events/' + evento.id,
//                 {
//                     status: 0

//                 },
//                 {
//                     //params: { region, type, sector }, 
//                     headers: {
//                         'Content-Type': 'application/json',
//                         'Authorization': `Bearer ${token.token}`
//                     }
//                 }
//             );

//             navigate('/admin');

//         } catch (error) {
//             console.log(error);
//         }
//     }

//     const handleClickDelete = async () => {

//         if (token) {
//             const { iat, exp } = token;

//             const dateToCheck = new Date(exp * 1000) - new Date();

//             if (dateToCheck < 0) {
//                 sessionStorage.clear();
//                 window.location.reload();
//             }
//             else if (dateToCheck < (1 * 60 * 1000)) {

//                 const response = await axios.post(
//                     url + '/oauth/access_token/refresh',
//                     {
//                         grant_type: "refresh_token",
//                         refresh_token: token.token
//                     }, {
//                     headers: {
//                         'Content-Type': 'application/json',
//                     }
//                 });


//                 if (response.data) {

//                     token = response.data;
//                     token.ctime = new Date().getTime();

//                     sessionStorage.setItem('token', JSON.stringify(token));
//                 }

//             }
//         } else {
//             alert('No token found');
//             sessionStorage.clear();
//             window.location.reload();
//         }





//         try {
//             const response = await axios.delete(
//                 url + '/events/' + evento.id,
//                 {
//                     //params: { region, type, sector }, 
//                     headers: {
//                         'Content-Type': 'application/json',
//                         'Authorization': `Bearer ${token.token}`
//                     }
//                 }
//             );

//             navigate('/admin');

//         } catch (error) {
//             console.log(error);
//         }
//     }



//     return (
//         <>

//             <React.Fragment >
//                 <Grid xs={12} md={12} sx={{ p: 2 }}>
//                     <Box>
//                         <Typography>Titolo evento:</Typography>
//                     </Box>
//                 </Grid>

//                 <Grid xs={12} md={6} sx={{ p: 2 }}>
//                     <Box>
//                         <TextField id="outlined-basic"
//                             InputLabelProps={{ shrink: true }}
//                             sx={{ width: '100%' }}
//                             value={evento.id}
//                             disabled
//                             label="ID evento"
//                             variant="outlined"
//                             onChange={(event) => {
//                                 const value = event.target.value;
//                                 setEvent({ ...obj, id: value, })
//                             }}

//                         />
//                     </Box>
//                 </Grid>

//                 <Grid xs={12} md={6} sx={{ p: 2 }}>
//                     <Box>
//                         <TextField id="outlined-basic"
//                             InputLabelProps={{ shrink: true }}
//                             sx={{ width: '100%' }}
//                             label="Name"
//                             variant="outlined"
//                             value={evento.name}
//                             onChange={(event) => {
//                                 const value = event.target.value;
//                                 setEvent({ ...obj, name: value, })
//                             }}
//                         />
//                     </Box>
//                 </Grid>

//                 <Grid xs={12} md={6} sx={{ p: 2 }}>
//                     <Box>
//                         <TextField
//                             id="outlined-basic"
//                             InputLabelProps={{ shrink: true }}
//                             sx={{ width: '100%' }}
//                             label="Place"
//                             variant="outlined"
//                             value={evento.place}
//                             onChange={(event) => {
//                                 const value = event.target.value;
//                                 setEvent({ ...obj, place: value, })
//                             }}
//                         />
//                     </Box>
//                 </Grid>


//                 <Grid xs={12} md={6} sx={{ p: 2 }}>
//                     <Box>


//                         <TextField type="datetime-local"
//                             sx={{ width: '100%' }}
//                             label="Date"
//                             InputLabelProps={{ shrink: true }}
//                             onChange={(event) => {
//                                 const value = event.target.value;
//                                 setEvent({ ...obj, date_at: value, })

//                             }}
//                             value={evento.date_at} />

//                     </Box>
//                 </Grid>




//                 <Grid xs={12} md={6} sx={{ p: 2 }}>
//                     <Box>
//                         <TextField
//                             id="outlined-basic"
//                             InputLabelProps={{ shrink: true }}
//                             sx={{ width: '100%' }}
//                             label="Description"
//                             variant="outlined"
//                             value={evento.description}
//                             onChange={(event) => {
//                                 const value = event.target.value;
//                                 setEvent({ ...obj, description: value, })
//                             }}
//                         />
//                     </Box>
//                 </Grid>

//                 <Grid xs={12} md={6} sx={{ p: 2 }}>
//                     <Box>
//                         <TextField
//                             id="outlined-basic"
//                             InputLabelProps={{ shrink: true }}
//                             sx={{ width: '100%' }}
//                             label="Fee"
//                             variant="outlined"
//                             value={evento.fee}
//                             onChange={(event) => {
//                                 const value = event.target.value;
//                                 setEvent({ ...obj, fee: value, })
//                             }}
//                         />
//                     </Box>
//                 </Grid>




//                 <Grid xs={12} md={6} sx={{ p: 2 }}>
//                     <Box>
//                         <TextField
//                             id="outlined-basic"
//                             InputLabelProps={{ shrink: true }}
//                             disabled sx={{ width: '100%' }}
//                             label="Organizer firstname"
//                             variant="outlined"
//                             value={evento.firstnameUser}
//                             onChange={(event) => {
//                                 const value = event.target.value;
//                                 setEvent({ ...obj, firstnameUser: value, })
//                             }}
//                         />
//                     </Box>
//                 </Grid>

//                 <Grid xs={12} md={6} sx={{ p: 2 }}>
//                     <Box>
//                         <TextField
//                             id="outlined-basic"
//                             InputLabelProps={{ shrink: true }}
//                             disabled sx={{ width: '100%' }}
//                             label="Organizer lastname"
//                             variant="outlined"
//                             value={evento.lastnameUser}
//                             onChange={(event) => {
//                                 const value = event.target.value;
//                                 setEvent({ ...obj, lastnameUser: value, })
//                             }}
//                         />
//                     </Box>
//                 </Grid>



//                 <Grid xs={12} md={6} sx={{ p: 2 }}>
//                     <Box>
//                         <TextField
//                             id="outlined-basic"
//                             InputLabelProps={{ shrink: true }}
//                             sx={{ width: '100%' }}
//                             label="URL"
//                             variant="outlined"
//                             value={evento.url}
//                             onChange={(event) => {
//                                 const value = event.target.value;
//                                 setEvent({ ...obj, url: value, })
//                             }}
//                         />
//                     </Box>
//                 </Grid>

//                 <Grid xs={12} md={12} sx={{ p: 2 }}>
//                     <Box>
//                         <Typography className="Cabin Regular size24px">:</Typography>
//                     </Box>
//                 </Grid>

//                 <Grid xs={12} md={6} sx={{ p: 2 }}>
//                     <Box
//                         sx={{
//                             display: 'flex',
//                             flexDirection: { xs: 'column', md: 'row' },
//                             flexWrap: 'nowrap',
//                             justifyContent: 'space-around',
                           
                           
//                         }}
//                     >
//                         <Box>

//                             <Button  sx={{ color: 'white', backgroundColor: '#B91D8B', my:{xs:2,md:0} , width:'100%' }} variant="contained" onClick={handleClickEdit} startIcon={<Edit className="white" />}>
//                                 <Typography component="span" className="Cabin Regular size16px">Edit</Typography>
//                             </Button>

//                         </Box>

//                         {evento.status === 1 ? (

//                             <Box>
//                                 <Link to="/event">
//                                     <Button sx={{ color: 'white', backgroundColor: '#B91D8B' , my:{xs:2,md:0} , width:'100%' }} variant="contained" onClick={handleClickHide} startIcon={<ContentPasteOff className="white" />}>
//                                         <Typography component="span" className="Cabin Regular size16px">Hide</Typography>
//                                     </Button>
//                                 </Link>
//                             </Box>
//                         ) : (
//                             <Box>
//                                 <Link to="/event">
//                                     <Button style={{width:'100%'}} sx={{ color: 'white', backgroundColor: '#B91D8B', my:{xs:2,md:0} , width:'100%' }} variant="contained" onClick={handleClickShow} startIcon={<ContentPaste className="white" />}>
//                                         <Typography component="span" className="Cabin Regular size16px">Show</Typography>
//                                     </Button>
//                                 </Link>
//                             </Box>
//                         )}


//                         <Box>
//                             <Link to="/event">
//                                 <Button sx={{ color: 'white', backgroundColor: '#B91D8B', my:{xs:2,md:0} , width:'100%'}} variant="contained"  onClick={handleClickDelete} startIcon={<Delete className="white" />}>
//                                     <Typography component="span" className="Cabin Regular size16px">Delete</Typography>
//                                 </Button>
//                             </Link>
//                         </Box>

//                     </Box>
//                 </Grid>

//             </React.Fragment>

//         </>
//     )
// };


// const columns = [
//     { id: 'id', label: 'ID', minWidth: 50 },
//     { id: 'name', label: 'Name', minWidth: 170 },
//     { id: 'place', label: 'Place', minWidth: 170 },
//     { id: 'date_at', label: 'Date', minWidth: 170 },
//     { id: 'organizer', label: 'Organizer', minWidth: 170 },
//     { id: 'operation', label: 'Operation', minWidth: 170 },

// ];


// const CardAllEvent = (props) => {
//     const [searchValue, setSearchValue] = useState('');
//     var empty = false;
//     const navigate = useNavigate();
//     const { allEvent } = props;

//     const [event, setEvent] = useState();


//     const [date, setDate] = useState({
//         date_start: '',
//         date_end: '',
//         stringa:'',
//     });

//     var { query = '' } = useParams();

//     useEffect(() => {

//         const loadData = async () => {

//             if (token) {
//                 const { iat, exp } = token;

//                 const dateToCheck = new Date(exp * 1000) - new Date();

//                 if (dateToCheck < 0) {
//                     sessionStorage.clear();
//                     window.location.reload();
//                 }
//                 else if (dateToCheck < (1 * 60 * 1000)) {

//                     const response = await axios.post(
//                         url + '/oauth/access_token/refresh',
//                         {
//                             grant_type: "refresh_token",
//                             refresh_token: token.token
//                         }, {
//                         headers: {
//                             'Content-Type': 'application/json',
//                         }
//                     });


//                     if (response.data) {

//                         token = response.data;
//                         token.ctime = new Date().getTime();

//                         sessionStorage.setItem('token', JSON.stringify(token));
//                     }

//                 }
//             }

//             else {
//                 alert('No token found');
//                 sessionStorage.clear();
//                 window.location.reload();
//             }

//             try {
//                 const response = await axios.get(
//                     url + '/events',
//                     {
//                         //params: { region, type, sector }, 
//                         headers: {
//                             'Content-Type': 'application/json',
//                             'Authorization': `Bearer ${token.token}`
//                         }
//                     }
//                 );
//                 setEvent(response.data.data);


//             } catch (error) {
//                 console.log(error);
//             }


//         }

//         loadData();



//     }, [])



//     var arrayFinal = [];
//     var arrayIntermediate = [];


//     if (query !== '') {
//         const parsedQuery = JSON.parse(query);

//         if(parsedQuery.date_start !== '' && parsedQuery.date_end !== '' && parsedQuery.stringa !== ''){
//             arrayIntermediate = allEvent.filter(elemento => {
//                 return ['organizer', 'name', 'place','firstname','lastname'].some(campo => {
//                     const valoreFiltro = parsedQuery.stringa.toLowerCase();
//                     const valoreCampo = elemento[campo].toLowerCase();

                

//                     const filtroNormalizzato = valoreFiltro.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
//                     const stringaNormalizzata = valoreCampo.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

//                     const stringaNormalizzata2 = stringaNormalizzata.replace(/\s/g, '')
//                     const filtroNormalizzato2 = filtroNormalizzato.replace(/\s/g, '')

                    
//                     return typeof valoreCampo === 'string' && stringaNormalizzata2 === filtroNormalizzato2;

                   

//                 });
//             });

//             console.log(arrayIntermediate)
//             console.log("Query2")

//             console.log(arrayIntermediate);

//             arrayFinal = arrayIntermediate.filter((obj) => obj.date_at >= parsedQuery.date_start && obj.date_at <= parsedQuery.date_end);
            

//         }else if( parsedQuery.stringa === '' && parsedQuery.date_start !== '' && parsedQuery.date_end !== ''){
//             arrayFinal = allEvent.filter((obj) => obj.date_at >= parsedQuery.date_start && obj.date_at <= parsedQuery.date_end);
//         }else if(parsedQuery.stringa !== '' && parsedQuery.date_start === '' && parsedQuery.date_end === ''){
//             var i=0;
//             arrayFinal = allEvent.filter(elemento => {
              
//                 return ['organizer', 'name', 'place','firstname','lastname'].some(campo => {
//                     const valoreFiltro = parsedQuery.stringa.toLowerCase();
//                     const valoreCampo = elemento[campo].toLowerCase();

                

//                     const filtroNormalizzato = valoreFiltro.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
//                     const stringaNormalizzata = valoreCampo.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

//                     const stringaNormalizzata2 = stringaNormalizzata.replace(/\s/g, '')
//                     const filtroNormalizzato2 = filtroNormalizzato.replace(/\s/g, '')

                    
//                     return typeof valoreCampo === 'string' && stringaNormalizzata2 === filtroNormalizzato2;

                   

//                 });
//             });
//         }
//         empty = (arrayFinal.length === 0 ? true : false)
//      }else{
//         arrayFinal = allEvent;
//     }
        
    


//     const handleInputChange = (event) => {
//         setSearchValue(event.target.value);
//     };

//     const handleClickSearchEvent = () => {
//         navigate(`/admin/${searchValue}`)
//     };


//     return (
//         <Card sx={{ paddingLeft: 0, paddingRight: 0, mx: { xs: 1, md: 3 }, borderRadius: '2px solid #B91D8B' }}>
//             <CardContent>

//                 <Grid container sx={{}} >
                   
//                     <Grid xs={12}>
//                         <Grid container>
//                             <Grid xs={12} sm={4}>
//                                 <Box sx={{  borderRadius: '5px!important',mx: 2, my:{xs:2,md:0} }}>
//                                     <TextField

//                                         sx={{  width: '100%' }}
//                                         label="Search"
//                                         value={date.stringa}
//                                         onChange={(event) => {
//                                             const value = event.target.value;
//                                             setDate({ ...date, stringa: value, })
    
//                                         }}
//                                     />


                                 
//                                 </Box>
//                             </Grid>
//                             <Grid xs={12} sm={4}>
//                             <Box sx={{ mx: 2 , my:{xs:2,md:0} }}>
//                                 <TextField type="datetime-local"
//                                     sx={{ width: '100%' }}
//                                     label="Date start"
//                                     InputLabelProps={{ shrink: true }}
//                                     onChange={(event) => {
//                                         const value = event.target.value;
                                       
//                                         setDate({ ...date, date_start: value, })

//                                     }}
//                                     value={date.date_start} />
//                             </Box>
//                             </Grid>
//                             <Grid xs={12} sm={4}>
//                             <Box sx={{ mx: 2 , my:{xs:2,md:0} }}>
//                                 <TextField type="datetime-local"
//                                     sx={{ width: '100%' }}
//                                     label="Date end"
//                                     InputLabelProps={{ shrink: true }}
//                                     onChange={(event) => {
//                                         const value = event.target.value;
//                                         setDate({ ...date, date_end: value, })

//                                     }}
//                                     value={date.date_end} />
//                             </Box>
//                             </Grid>
//                             <Grid xs={12}>
//                             <Box sx={{ pt: 2 ,display:'flex' , justifyContent:'center'}}>
//                                 <Button variant="contained"  onClick={() => {
//                                     var searchValue = {
//                                         date_start : '',
//                                         date_end : '',
//                                         stringa : ''
//                                     }


                                
//                                     if(date.stringa !== ''){
//                                         searchValue.stringa = date.stringa;
//                                     }
                                    
//                                     if(date.date_start !== '' && date.date_end !== ''){
//                                         searchValue.date_start = date.date_start;
//                                         searchValue.date_end = date.date_end;
//                                     }

//                                     console.log(searchValue);
//                                     navigate(`/admin/${JSON.stringify(searchValue)}`)
                                   
                                   
                                    
//                                 }}
//                                 sx={{ width: {xs:'100%', md:'20%'} }} >Cerca</Button>
//                             </Box>
//                             </Grid>
//                         </Grid>
//                     </Grid>




//                     <Grid xs={12} md={2} sx={{
//                         px: 3,
//                         padding: 0,
//                         borderRight: { md: '1px solid #B91D8B', xs: 'none' },
//                         borderBottom: { xs: '1px solid #B91D8B', md: 'none' }

//                     }} >

//                         <Box
//                             sx={{
//                                 display: 'flex',
//                                 flexDirection: 'column',
//                                 p: 1,
//                                 m: 1,
//                                 justifyContent: 'space-around',

//                             }}
//                         >



//                             <Box>
//                                 <Link to="/admin">
//                                     <Button sx={{ color: 'black', width: '100%', height: '100px' }} startIcon={<Home className="white" />}>
//                                         <Typography component="span" className="Cabin Regular size16px">Home</Typography>
//                                     </Button>
//                                 </Link>
//                             </Box>

//                             <Box>
//                                 <Button sx={{ color: 'black', width: '100%', height: '100px' }} onClick={handleClickLogout} startIcon={<Logout className="white" />}>
//                                     <Typography component="span" className="Cabin Regular size16px">Logout</Typography>
//                                 </Button>
//                             </Box>

//                             <Box>
//                                 {query !== '' ? (
//                                     <Link to="/admin">
//                                         <Button sx={{ color: 'black', width: '100%', height: '100px' }} onClick={() => {
//                                             setDate({
//                                                 date_start: '',
//                                                 date_end: '',
//                                                 stringa:''
//                                             })
//                                         }} startIcon={<CleaningServices className="white" />}>
//                                             <Typography component="span" className="Cabin Regular size16px">Clear filter</Typography>
//                                         </Button>
//                                     </Link>
//                                 ) : null}
//                             </Box>


//                         </Box>

//                     </Grid>

//                     <Grid xs={12} md={10}  >
//                         <Grid container sx={{ my: 2 }}>
//                             <Grid xs={12} sx={{ py: 2, px: 2 }}>


//                                 <Button startIcon={<Event sx={{ width: '50px', height: '50px' }} />}>
//                                     <Typography sx={{ textTransform: 'capitalize', color: 'black' }} className="Cabin Regular size24px">Eventi</Typography>
//                                 </Button>

//                             </Grid>
//                             <Grid xs={12} sx={{ my: 3, px: 2 }}>
//                                 {arrayFinal.length !== 0 ? (
//                                     <MyTable rows={arrayFinal} columns={columns} />
//                                 ) : (
//                                     <React.Fragment>
//                                         {empty ? (
//                                             <Typography style={{ textTransform: 'capitalize', color: 'black' }} className="Cabin Regular size24px">
//                                                 Not event found
//                                             </Typography>
//                                         ) : (
//                                             <Stack spacing={{ xs: 1, sm: 2 }} direction="row" useFlexGap flexWrap="wrap">
//                                                 <Skeleton variant="rectangular" sx={{ width: '100%' }} height={40} />
//                                                 <Skeleton variant="rectangular" sx={{ width: '100%' }} height={60} />
//                                                 <Skeleton variant="rectangular" sx={{ width: '100%' }} height={30} />
//                                                 <Skeleton variant="rectangular" sx={{ width: '100%' }} height={60} />
//                                                 <Skeleton variant="rectangular" sx={{ width: '100%' }} height={30} />
//                                                 <Skeleton variant="rectangular" sx={{ width: '100%' }} height={60} />
//                                             </Stack>
//                                         )}
//                                     </React.Fragment>
//                                 )}
//                             </Grid>

//                         </Grid>
//                     </Grid>
//                 </Grid>
//             </CardContent>
//         </Card>
//     )
// }



// const CardSingleEvent = () => {
//     const { idEvent, NameUser = '' } = useParams();
//     const [singleEvent, setsingleEvent] = useState();
//     const [searchValue, setSearchValue] = useState('');
//     const navigate = useNavigate();

//     const [date, setDate] = useState({
//         date_start: '',
//         date_end: ''
//     });

//     useEffect(() => {

//         const loadData = async () => {

//             if (token) {
//                 const { iat, exp } = token;

//                 const dateToCheck = new Date(exp * 1000) - new Date();

//                 if (dateToCheck < 0) {
//                     sessionStorage.clear();
//                     window.location.reload();
//                 }
//                 else if (dateToCheck < (1 * 60 * 1000)) {

//                     const response = await axios.post(
//                         url + '/oauth/access_token/refresh',
//                         {
//                             grant_type: "refresh_token",
//                             refresh_token: token.token
//                         }, {
//                         headers: {
//                             'Content-Type': 'application/json',
//                         }
//                     });


//                     if (response.data) {

//                         token = response.data;
//                         token.ctime = new Date().getTime();

//                         sessionStorage.setItem('token', JSON.stringify(token));
//                     }

//                 }
//             }

//             else {
//                 alert('No token found');
//                 sessionStorage.clear();
//                 window.location.reload();
//             }



//             try {
//                 const response = await axios.get(
//                     url + '/events',
//                     {
//                         //params: { region, type, sector }, 
//                         headers: {
//                             'Content-Type': 'application/json',
//                             'Authorization': `Bearer ${token.token}`
//                         }
//                     }
//                 );
//                 setsingleEvent(response.data.data);


//             } catch (error) {
//                 console.log(error);
//             }
//         }

//         if (idEvent !== '0') {
//             loadData();
//         }

//     }, [])

//     const Events = ((typeof singleEvent !== 'undefined') ? Object.values(singleEvent) : []);



//     const allEvent = Events.map(obj => ({
//         id: obj.id,
//         fee: obj.fee,
//         url: obj.url,
//         name: obj.name,
//         description: obj.description,
//         date_at: obj.date_at.slice(0, -8),
//         place: obj.place,
//         idUser: obj.user.id,
//         organizer: obj.user.firstname + ' ' + obj.user.lastname, 
//         status: obj.status,
//         operation: buttonOpen(obj.id)
//     }));


//     const eventUser = allEvent.filter(obj => obj.id === idEvent);
//     const eventsUser = allEvent.filter(obj => obj.idUser === eventUser[0].idUser && obj.id !== idEvent);
//     const organizer = ((eventUser.length !== 0) ? eventUser[0].organizer : null);

//     const handleInputChange = (event) => {
//         setSearchValue(event.target.value);
//     };

//     const handleClickSearchEvent = () => {
//         // setLinkTo(`/event/6/${searchValue}`);
//         navigate(`/admin/${searchValue}`)
//     };





//     return (
//         <Card sx={{ paddingLeft: 0, paddingRight: 0, mx: { xs: 1, md: 3 }, borderRadius: '2px solid #B91D8B' }}>
//             <CardContent>

//                 <Grid container  >


//                     <Grid xs={12} md={2} sx={{
//                         px: 3,
//                         padding: 0,
//                         borderRight: { md: '1px solid #B91D8B', xs: 'none' },
//                         borderBottom: { xs: '1px solid #B91D8B', md: 'none' }
//                     }} >

//                         <Box
//                             sx={{
//                                 display: 'flex',
//                                 flexDirection: { xs: 'row', md: 'column' },
//                                 p: 1,
//                                 m: 1,
//                                 justifyContent: 'space-around',

//                             }}
//                         >
//                             <Box>
//                                 <Link to="/admin">
//                                     <Button sx={{ color: 'black', width: '100%', height: '100px' }} startIcon={<Home className="white" />}>
//                                         <Typography component="span" className="Cabin Regular size16px">Home</Typography>
//                                     </Button>
//                                 </Link>
//                             </Box>

//                             <Box>
//                                 <Button sx={{ color: 'black', width: '100%', height: '100px' }} onClick={handleClickLogout} startIcon={<Logout className="white" />}>
//                                     <Typography component="span" className="Cabin Regular size16px">Logout</Typography>
//                                 </Button>
//                             </Box>


//                         </Box>

//                     </Grid>

//                     <Grid xs={12} md={10}  >
//                         <Grid container >


//                             <Grid xs={12} sx={{ px: 2 }}>


//                                 <Button startIcon={<Event sx={{ width: '50px', height: '50px' }} />}>
//                                     <Typography sx={{ textTransform: 'capitalize', color: 'black' }} className="Cabin Regular size24px">Evento</Typography>
//                                 </Button>

//                             </Grid>
//                             <Grid xs={12} sx={{ my: 3, px: 2 }}>
//                                 {eventUser.length != 0 ? (

//                                     <Grid container >


//                                         <ListValueEvent eventsUser={eventUser} />

//                                         <Divider sx={{ width: '98%', my: 2, mx: 2, backgroundColor: '#C0087F' }} />
//                                         <Grid xs={12} md={12} sx={{ p: 2 }}>
//                                             <Box>
//                                                 <Typography className="Cabin Regular size24px">Events organized by the user: {organizer}</Typography>
//                                             </Box>
//                                         </Grid>

//                                         <MyTable rows={eventsUser} columns={columns} />



//                                     </Grid>

//                                 ) : (
//                                     <Stack spacing={{ xs: 1, sm: 2 }} direction="row" useFlexGap flexWrap="wrap">

//                                         <Skeleton variant="rectangular" sx={{ width: '100%' }} height={40} />
//                                         <Skeleton variant="circular" width={100} height={100} />
//                                         <Skeleton variant="rectangular" sx={{ width: '100%' }} height={30} />
//                                         <Skeleton variant="rectangular" sx={{ width: '100%' }} height={60} />
//                                         <Skeleton variant="rectangular" sx={{ width: '100%' }} height={30} />
//                                         <Skeleton variant="rectangular" sx={{ width: '100%' }} height={60} />

//                                     </Stack>
//                                 )
//                                 }
//                             </Grid>

//                         </Grid>
//                     </Grid>
//                 </Grid>
//             </CardContent>
//         </Card>
//     )
// }




export default function Admin(props) {

    const drawerWidth = 240;

    const navigate = useNavigate();
    const [events, setEvents] = useState([]);



    useEffect(() => {

        const loadData = async () => {

            if (token) {
                const { iat, exp } = token;

                const dateToCheck = new Date(exp * 1000) - new Date();

                if (dateToCheck < 0) {
                    sessionStorage.clear();
                    window.location.reload();
                }
                else if (dateToCheck < (1 * 60 * 1000)) {

                    const response = await axios.post(
                        url + '/oauth/access_token/refresh',
                        {
                            grant_type: "refresh_token",
                            refresh_token: token.token
                        }, {
                        headers: {
                            'Content-Type': 'application/json',
                        }
                    });


                    if (response.data) {

                        token = response.data;
                        token.ctime = new Date().getTime();

                        sessionStorage.setItem('token', JSON.stringify(token));
                    }

                }
            } else {
                alert('No token found');
                sessionStorage.clear();

                window.location.reload('admin');
            }


            try {
                const response = await axios.get(
                    url + '/events?sort=id:DESC',
                    {
                        //params: { region, type, sector }, 
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${token.token}`
                        }
                    }
                );
                setEvents(response.data.data);


            } catch (error) {
                console.log(error);
            }

        }

        loadData();

    }, [])


    const onExit = () => {
        sessionStorage.clear();
        window.location.reload();
    };
    


    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar
                position="fixed"
                sx={{ width: `calc(100% - ${drawerWidth}px)`, ml: `${drawerWidth}px` }}
            >
                <Toolbar>
                    <Typography variant="h6" noWrap component="div">
                        Events
                    </Typography>
                </Toolbar>
            </AppBar>
            <Drawer
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: drawerWidth,
                        boxSizing: 'border-box',
                    },
                }}
                variant="permanent"
                anchor="left"
            >
                <Toolbar >
                    <Box sx={{width:'100%'}} component='img' src="/AccelerAction_Logo.png" alt='Logo' />
                </Toolbar>   

                <Divider />
                
                <List>
                    {['Event', 'Exit'].map((text, index) => (
                        <ListItem key={text} disablePadding>
                            <ListItemButton onClick={ text === 'Exit' ? onExit : () => navigate('/admin')}>
                                <ListItemIcon>
                                    {text === 'Exit' ? <ExitToApp color="primary" /> : <Event color="primary" />}
                                </ListItemIcon>
                                <ListItemText primary={text} />
                            </ListItemButton>
                        </ListItem>
                    ))}
                </List>

            </Drawer>
            <Box
                component="main"
                sx={{ flexGrow: 1, bgcolor: 'background.default', p: 3 }}
            >
                <Toolbar />
                
                <Routes>
                    <Route path="/admin" element={<GridEvents events={events} />} />    
                    <Route path="/admin/:idEvent" element={<CardEvent />} />
                    <Route path="/" element={<Navigate to="/admin" replace />} />
                </Routes>
            </Box>
        </Box>
      );

}
